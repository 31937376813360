import React, { useState, useEffect } from 'react';
import AboutGrid from '../components/AboutGrid';

import {display_contactBubble,seo_tags,conn_error,scroll_top} from '../js/helper.js'

function About() {
	seo_tags({
      title: 'About'
    });

	const [aboutData, setAboutdata] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');
	useEffect(() => {
		scroll_top();
		fetch(process.env.REACT_APP_API_DOMAIN+"about")
		.then(res => res.json())
		.then(
			(result) => {
				display_contactBubble();
				setAboutdata(result)
			},
			(error) => {
				setErrorMessage(conn_error());
			}	
		)
	},[]);

	return (
		<React.Fragment>
			{ errorMessage.length ? <div className="error_message">{errorMessage}</div> : null }
			{ aboutData.length ? <AboutGrid aboutData={aboutData} /> : null }
		</React.Fragment>
	)
}

export default About
