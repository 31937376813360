import React, {  useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography';

import FooterWidget from './FooterWidget';
import ScrollTopArrow from './ScrollTopArrow';


import {remove_activeLink} from '../js/helper.js'

const useStyles = makeStyles((theme) => ({
	footer_menu_list:{
		margin:'0 0 20px 0'
	},
	footer_items:{
	  paddingBottom:'10px'
	},
  //   pimento_logo:{
  //     width:'80px',
  //     float:'right',
  //     marginTop:'16px'
  //   },
	collective_logo:{
	  width:'60px',
	  margin:'0 auto',
	  marginRight:'10px'
	},
	proud_member:{
	  fontSize: '18px',
	  margin: '20px 20px 0',
	  fontFamily: 'rajdhani',
	  padding: '20px 0 0 0'
	},
	footer_logo_cont:{
	  border:'0px solid black',
	  float:'left',
	  position:'relative',
	  //marginTop:'5px',
	  display: 'inline-flex',
	  justifyContent: 'center',
	  marginTop:'20px',
	  padding: '20px 0 0 0'
	},
	saperator_image:{
	  width:'50%',
	  padding:'25px 0',
	  margin:'0 auto'
	}
  }));

function FooterLinks() {
  const classes = useStyles();

  useEffect(() => {
    var footer_links = document.getElementsByClassName("footer_links"); 

	for (var i = 0; i < footer_links.length; i++) {
		footer_links[i].addEventListener('click', footer_link_click, false);
	}

	function footer_link_click(){
		remove_activeLink();
	}
  },[]);
  
  return (
    <div className="footer_section">

		<FooterWidget/>
		
		<Grid container className={classes.footer_menu_list}>
			<ScrollTopArrow />
			<div className="footerLinksWrap">
				<Grid item xs={12} sm={4} md={4} lg={4} className={classes.footer_items}>
					<Link to="/policy-and-cookies" color="inherit" className="footer_links">
						GDPR, Privacy and Cookies policy
					</Link>
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={4} className={classes.footer_items}>
					<Link to="/security-policy" color="inherit" className="footer_links">
						Security policy
					</Link>
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={4} className={classes.footer_items}>
					<Link to="/historic" color="inherit" className="footer_links">
						Archive
					</Link>
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={4}>
					<Link to="/impressum" color="inherit" className="footer_links">
						Impressum
					</Link>
				</Grid>
			</div>
			
			<Grid item container xs={12} className={classes.footer_items}>
				{/* <Grid item xs={6} className={classes.footer_logo_cont}>
					<a href="https://pimento.co.uk/"  target="_blank" rel="noopener" color="inherit">
						<CardMedia
						  component="img"
						  alt="Pimento Member"
						  image="../pimento_logo.png"
						  title="Pimento Member"
						  className={classes.pimento_logo}
						/>
					</a>
				</Grid> */}
				
				<Grid item xs={12} className={classes.footer_logo_cont}>
					<a href="https://www.google.com/partners/agency?id=5945915283"  target="_blank" rel="noopener noreferrer" color="inherit">
						<CardMedia
						  component="img"
						  alt="Google Partner"
						  image="/PartnerBadgeClickable.svg"
						  title="Google Partner"
						  className={classes.collective_logo}
						/>
					</a>
				</Grid>
			</Grid>
		</Grid>
    </div>
  )
}

export default FooterLinks
