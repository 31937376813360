import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Link } from "react-router-dom";
import {seo_tags} from '../js/helper.js'

const useStyles = makeStyles((theme) => ({
	PNF_wrapper: {
		padding: '60px 30px',
	},
	error_message: {
		fontFamily: 'Rajdhani',
	},
	homepageLink: {
		display: 'inline-block',
		marginTop: '20px',
	},
	contact_button:{
		display: 'inline-block',
		border: '0.5px solid black',
		boxShadow: 'none',
		fontFamily: 'Rajdhani',
		margin: '0 20px 20px',
		padding: '10px 20px 10px 20px',
		minWidth: '232px',
		
		'&:hover':{
			boxShadow: 'none'
		}
	}
}));

function PageNotFound() {
	seo_tags({
      title: '404 - page not found'
    });

	const classes = useStyles();

	return (
		<React.Fragment>
			<div className={classes.PNF_wrapper}>
				<Typography variant="h4" className={classes.error_message}>
					You have come to the wrong place!
				</Typography>
				<Typography variant="h5" className={classes.error_message}>
					404 - page not found
				</Typography>
				<Link className={classes.homepageLink} to="/">
					<Button xs={12} className={classes.contact_button} variant="contained">
						Click here to get to the home page
					</Button>
				</Link>
			</div>
		</React.Fragment>
	)
}

export default PageNotFound
