import React from 'react'
//import { makeStyles } from '@material-ui/core/styles';


import '../css/FooterWidget.scss';
/*
const useStyles = makeStyles((theme) => ({
        mail_icon: {
            color  : '#2997d9',
            position: 'absolute',
            top: '20px',
            right: '21px',
            "&:hover": {
                color: '#1057a0'
            }
        },
        footer_items:{
            border:'1px solid black',
            float:'left'
        },
        footer_menu_list:{
            marginTop:'190px',
            border:'1px solid black'
        }
    }));
*/
    const mailTo = () =>{
       
        let emailto = String.fromCharCode(104,101,108,108,111,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109);
        window.location = 'mailto:'+ emailto +'?';
       
    }

function FooterWidget() {
    //const classNamees = useStyles();

    return (
        <div className="footer_top">
            <nav className="footer_menu">
                <input type="checkbox" href="#" className="menu-open" name="menu-open" id="menu-open" />
                <label className="menu-open-button" htmlFor="menu-open"></label>
                
                <a href="https://www.facebook.com/sapnagroup" className="menu-item " target="_blank" rel="noopener noreferrer">&nbsp;</a>
                <a href="https://www.instagram.com/sapnagroup/" className="menu-item " target="_blank" rel="noopener noreferrer">&nbsp;</a>
                <a href="https://www.linkedin.com/in/jonnyhubner" className="menu-item " target="_blank" rel="noopener noreferrer">&nbsp;</a>
                <a href="https://www.xing.com/profile/Jonny_Huebner2" className="menu-item " target="_blank" rel="noopener noreferrer">&nbsp;</a>
                <a href="https://twitter.com/sapnagroup" className="menu-item " target="_blank" rel="noopener noreferrer">&nbsp;</a>
                <span  onClick={() => mailTo()} className="menu-item" style={{cursor:'pointer'}}>&nbsp;</span>
            </nav>
        </div>
    )
}

export default FooterWidget
