import React,{useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';

import {seo_tags,scroll_top} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
	grid_item: {
		marginBottom: '0'
	},
	intro_para:{
		width: '80%',
		flexBasis: '80%',
		margin:'0 auto 25px'
	},
	archive_image: {
		height: 'auto',
		width:'100%',
		maxWidth:'247px',
		margin:'0 auto'
	},
	archive_image_2016: {
		height: 'auto',
		width:'100%',
		maxWidth:'247px',
		margin:'0 auto',
		paddingTop:'5px'
	},
  emp_name: {
    textAlign: 'center',
    fontSize: '21px;',
    fontFamily: 'Rajdhani',
    fontWeight: 'bold'
  },
  archive_title: {
    textAlign: 'center',
    fontSize: '18px;',
    fontFamily:'Rajdhani',
    color: '#000'
  },
	archive_link:{
		outline:'0',
		textDecoration:'underline',
		'&:active': {
			outline: 0
		},
		'&:focus': {
			outline: 0
		}
	},
  container_div: {
    padding:'0 55px'
  },
  grid_paper:{
    margin:'0 auto'
  },
  saperator_image:{
    width:'50%',
    paddingBottom:'15px',
    paddingTop:'5px',
    margin:'0 auto'
  },
  site_year:{
	  fontFamily:'Rajdhani',
	  fontSize:'18px'
  },

}));

function Archives() {
	const classes = useStyles();

	seo_tags({
      title: 'Historic Websites'
    });

	useEffect(() => {
		scroll_top();
	},[]);

	return (
    <React.Fragment>
		<Grid className="maxWrapper archiveList" container px={5}>
			<Grow in={true}>
				<Grid className={classes.archive_title} item xs={12}>
					<CardMedia
						component="img"
						alt="archive title"
						image="../page-headline/Archives.png"
						title="archive title"
						className="page_header_image"
					/>
				</Grid>
			</Grow>
		
			<Grow in={true}>
				<Grid className={classes.intro_para} item xs={12}>
					Thanks to archive.org and to our own archive we managed to find all the different websites we had since 2002. Click on the thumbnails to jump back in time! 
				</Grid>
			</Grow>

			<Grow in={true}>
				<Grid className={`${classes.grid_item} ${classes.archive_link} academyGridBox`} item xs={12} sm={12} md={6} lg={4} style={{ padding: '20px 40px 20px' }} >
					<a target="_blank" href="/archive/2002a/index.htm">
						<CardMedia
							component="img"
							alt="sapnagroup website 2002"
							image="../archive/images/2002a.png"
							title="sapnagroup website 2002"
							className={classes.archive_image}
						/>
						<Typography className={classes.site_year}>2002</Typography>
					</a>
				</Grid>
			</Grow>

			<Grow in={true}>
				<Grid className={`${classes.grid_item} ${classes.archive_link} academyGridBox`} item xs={12} sm={12} md={6} lg={4} style={{ padding: '20px 40px 20px' }} >
					<a target="_blank" href="/archive/2002b/index.htm">
						<CardMedia
							component="img"
							alt="sapnagroup website 2002"
							image="../archive/images/2002b.png"
							title="sapnagroup website 2002"
							className={classes.archive_image}
						/>
						<Typography className={classes.site_year}>2002</Typography>
					</a>
				</Grid>
			</Grow>

			<Grow in={true}>
				<Grid className={`${classes.grid_item} ${classes.archive_link} academyGridBox`} item xs={12} sm={12} md={6} lg={4} style={{ padding: '20px 40px 20px' }}>
					<a target="_blank" href="/archive/2003/index.html">
						<CardMedia
							component="img"
							alt="sapnagroup website 2003"
							image="../archive/images/2003.png"
							title="sapnagroup website 2003"
							className={classes.archive_image}
						/>
						<Typography className={classes.site_year}>2003</Typography>
					</a>
				</Grid>
			</Grow>
			
			<Grow in={true}>
				<Grid className={`${classes.grid_item} ${classes.archive_link} academyGridBox`} item xs={12} sm={12} md={6} lg={4} style={{ padding: '20px 40px 20px' }} >
					<a target="_blank" href="/archive/2006/index.htm">	
						<CardMedia
							component="img"
							alt="sapnagroup website 2006"
							image="../archive/images/2006.png"
							title="sapnagroup website 2006"
							className={classes.archive_image}
						/>
						<Typography className={classes.site_year}>2006</Typography>
					</a>
				</Grid>
			</Grow>

			<Grow in={true}>
				<Grid className={`${classes.grid_item} ${classes.archive_link} academyGridBox`} item xs={12} sm={12} md={6} lg={4} style={{ padding: '20px 40px 20px' }} >
					<a target="_blank" href="/archive/2007/index.htm">	
						<CardMedia
							component="img"
							alt="sapnagroup website 2007"
							image="../archive/images/2007.png"
							title="sapnagroup website 2007"
							className={classes.archive_image}
						/>
						<Typography className={classes.site_year}>2007</Typography>
					</a>
				</Grid>
			</Grow>

			<Grow in={true}>
				<Grid className={`${classes.grid_item} ${classes.archive_link} academyGridBox`} item xs={12} sm={12} md={6} lg={4} style={{ padding: '20px 40px 20px' }} >
					<a target="_blank" href="/archive/2009/index.html">
						<CardMedia
							component="img"
							alt="sapnagroup website 2009"
							image="../archive/images/2009.png"
							title="sapnagroup website 2009"
							className={classes.archive_image}
						/>
						<Typography className={classes.site_year}>2009</Typography>
					</a>
				</Grid>
			</Grow>


			<Grow in={true}>
				<Grid className={`${classes.grid_item} ${classes.archive_link} academyGridBox`} item xs={12} sm={12} md={6} lg={4} style={{ padding: '20px 40px 20px' }} >
					<a target="_blank" href="/archive/Offline-28Nov2014/www.sapnagroup.com/en/index.html">
						<CardMedia
							component="img"
							alt="sapnagroup website 2012"
							image="../archive/images/2012.png"
							title="sapnagroup website 2012"
							className={classes.archive_image}
						/>
						<Typography className={classes.site_year}>2012</Typography>
					</a>	
				</Grid>
			</Grow>

			<Grow in={true}>
				<Grid className={`${classes.grid_item} ${classes.archive_link} academyGridBox`} item xs={12} sm={12} md={6} lg={4} style={{ padding: '20px 40px 20px' }} >
					<a target="_blank" href="/archive/2016/index.html">
						<CardMedia
							component="img"
							alt="sapnagroup website 2016"
							image="../archive/images/website-2016.png"
							title="sapnagroup website 2016"
							className={classes.archive_image_2016}
						/>
						<Typography className={classes.site_year}>2016</Typography>
					</a>
				</Grid>
			</Grow>
		</Grid>
    </React.Fragment>
  )
}

export default Archives
