import React, { useState, useEffect, useRef } from 'react'; 
import '../css/brochure.scss';
//import BrochureSlider from './BrochureSlider';
import { Swiper, SwiperSlide } from "swiper/react";
import { BrowserRouter as Router, Route, Link, NavLink, useParams, useLocation  } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// import "./styles.css";

// import required modules
import { FreeMode,  Thumbs, Navigation } from "swiper";

// import {display_contactBubble,seo_tags,conn_error,scroll_top} from '../js/helper.js'

 

function Brochure() {
	// seo_tags({
    //   title: 'Brochure'
    // });

	// const [aboutData, setAboutdata] = useState(0);
	// const [errorMessage, setErrorMessage] = useState('');
	// useEffect(() => {
	// 	scroll_top();
	// 	fetch(process.env.REACT_APP_API_DOMAIN+"brochure")
	// 	.then(res => res.json())
	// 	.then(
	// 		(result) => {
	// 			display_contactBubble();
	// 			setBrochuredata(result)
	// 		},
	// 		(error) => {
	// 			setErrorMessage(conn_error());
	// 		}	
	// 	)
    // },[]);
    const [openLink, setOpenLink] = useState(0);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [activeNavItem, setActiveNavItem] = useState('All');
    const location = useLocation();

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    let slideTypeValue = 'imageRight';
    const [jsonData, setJsonData] =  useState([]);
    const swiperRef = useRef(null);

    const defaultlink = (index) => {
        setOpenLink(index);
   };

   

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavItemClick = () => {
    setIsMobileMenuOpen(false); // Close the dropdown after a navigation link is clicked
  };


    const navLinks = [
        { id: 1, title: 'All', path: '/portfolio/all'},
        { id: 2, title: 'About', path: '/portfolio/about'},
        { id: 3, title: 'Bespoke Software', path: '/portfolio/bespoke-software'} ,
        { id: 4, title: 'Wordpress', path: '/portfolio/wordpress'},
        { id: 5, title: 'Server Management', path: '/portfolio/server-management' },
        { id: 6, title: 'Apps', path: '/portfolio/apps' },
        { id: 7, title: 'Security', path: '/portfolio/security' },
        { id: 8, title: 'Digital Marketing', path: '/portfolio/digital-marketing' },
        { id: 9, title: 'Content Creation', path: '/portfolio/content-creation' },
        { id: 10, title: 'Other Services', path: '/portfolio/other-services' },
      ];

    const { id } = useParams();
    const history = useHistory();
	
	const fetchData = async () => {
		try {

            // Use fetch to fetch data
            const response = await fetch(process.env.REACT_APP_API_DOMAIN + "brochure");

            if (!response.ok) 
            {
                throw new Error('Network response was not ok');
            }

            // Parse the JSON response
            const data = await response.json();

            //const response = await axios.get(`/brochure/all.json?t=${new Date().getTime()}`); // Replace with the actual path to your JSON data
            //const response = await axios.get(`/brochure/all.json?t=${new Date().getTime()}`); // Replace with the actual path to your JSON data
            //const selectedCat = response.data.filter(item =>  item.slug.includes(id));
            //setJsonData(selectedCat.sort(getSortOrder(id)));


            //var check_url = location.pathname;
            const selectedCat = data.filter(item => item.slug.includes(id));
            selectedCat.sort((a, b) => a.order_no - b.order_no);
            setJsonData(selectedCat);
            /* if (check_url.endsWith("/all")) 
            {
			    selectedCat.sort((a, b) => a.all_order_no - b.all_order_no);
                setJsonData(selectedCat);
            } 
            else 
            {
			    selectedCat.sort((a, b) => a.order_no - b.order_no);
                setJsonData(selectedCat);
            } */

		} catch (error) {
			console.error('Error fetching data:', error);
		}	 
	}; 
    
	const getSortOrder = (prop) => {    
		return function(a, b) {    
			if (a[prop] > b[prop]) {    
				return 1;    
			} else if (a[prop] < b[prop]) {    
				return -1;    
			}    
			return 0;    
		}    
	};
	
    useEffect(() => {
        // Find the active navigation item's title and update the state
        const activeItem = navLinks.find(item => item.path === location.pathname);
        if (activeItem) {
            setActiveNavItem(activeItem.title);
        }
      }, [location]);

    useEffect(() => {    
        fetchData();       

        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(0, 0); // 0 is the slide index, and 0 is the transition duration in milliseconds
        }
        
        // Check if the URL ends with '/portfolio/' and navigate to '/portfolio/all'
        const check_url = location.pathname;
        if (check_url.endsWith("/portfolio/") || check_url === "/portfolio") 
        {
            history.push('/portfolio/all');
        }

        }, [id]);
    
	if (!jsonData) {
		return <div>Loading...</div>;
	}

	return (
		<React.Fragment>
        <Grid className="brochure-links maxWrapper" container px={4}>
            <button className={`brochure-mobile-menu ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu} id="brochure-mobile-menu">
                 {activeNavItem}
            </button>

            <div className={`brochurepagination ${isMobileMenuOpen ? 'open' : ''}`}>
                {navLinks.map((link) => (
                    <NavLink key={link.id} to={link.path} id={link.id} className="thumbnail-holder" exact activeClassName="active" onClick={() => handleNavItemClick()}>
                    {link.title}
                    </NavLink>
                ))}
            </div>
 
            {/* <BrochureSlider /> */}
        </Grid>


        <div className="brochureSliderwraper">
            <Swiper
                ref={swiperRef}
                observeSlideChildren={true}
                style={{
                    "--swiper-navigation-color": "#000",
                    "--swiper-pagination-color": "#000",
                }}
        
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"             
            >
            {jsonData.map((slide) => (
                <SwiperSlide  key={slide.id}>
                            
                    <Grid container className={ slide.slidetype !== slideTypeValue ? 'swiper-colmn-reverse' : ''}>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} md={slide.contentcol} align={slide.contentalign}>
                        {slide.iconContent !== "" ? (
                            <div className='smallImgWraper'>                                                  
                                    <h2 dangerouslySetInnerHTML={{ __html: slide.heading }} /> 
                                    <p>{slide.headingText}</p>
                                    {slide.iconContent.map((icondata, index) => (
                                    <div className='iconsTextBlock' key={index}>
                                        <div className="icons"><img src={icondata.iconImg} alt="" title="" /></div>                                            
                                         <p dangerouslySetInnerHTML={{ __html: icondata.iconDesc }}/>
                                    </div>  
                                    ))}  
                                    <h3 align={'left'}>{slide.subheading}</h3>
                                    <a target="_blank" href={`https://${slide.website}`} align={'left'}>{slide.website}</a> 
                                    <div>&nbsp;</div>   
                            </div>
                            
                                    ) : (
                            <div className={slide.slidetype === "imageRight" ? 'mr30' : 'ml30'}>
                            <h2 dangerouslySetInnerHTML={{ __html: slide.heading }} />  
                            <p dangerouslySetInnerHTML={{ __html: slide.description }} />  
                            </div>          
                            )}
                            
                        </Grid>
                       {/*  {slide.iconContent !== "" ? ( ""
                        ):(
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        )}   */}            
                        <Grid item xs={12} md={slide.imgCol} align={slide.imageAlignalign}>
                            <CardMedia
                                component="img"
                                alt={slide.imgName}
                                image={slide.url}
                                title={slide.imgName}
                                className="CoverPic ImageFix"
                            />
                        </Grid>            
                        
                        {/* <Grid item xs={12} md={1} align={'left'}></Grid> */}
                    </Grid>
                    {/* )} */}
                        
                    </SwiperSlide>     
            ))}
            </Swiper>


            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >

                {jsonData.map((slide) => (
                    <SwiperSlide className="thumbnails_wrap" key={slide.id}>  
                        
                        <div className="thumbnails-description" dangerouslySetInnerHTML={{ __html: slide.heading }} /> 
                        <img src={slide.thumbnailimage} alt={slide.heading} className='thumbnail-image'/>
                    </SwiperSlide>                          
                                
                        
                ))}
            </Swiper>
        </div>
		</React.Fragment>
	)

}
export default Brochure
