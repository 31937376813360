import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Link from '@material-ui/core/Link';

import {seo_tags,scroll_top} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
	page_title: {
		textAlign: 'center',
		margin: '0 0',
		fontSize: '18px'
	},
	container_div: {
   		padding:'0 30px',
   		border:'0px solid black'
  	},
	intro_para:{
    	paddingBottom:'30px'
  	},
	grid_item:{
		border:'0px solid black',
		textAlign:'center',
		padding:'25px 0'
	},
	tools_image:{
		border:'0px solid black',
		width:'57px',
		height:'auto',
		margin:'0 auto',
		paddingBottom:'15px'
	},
	tools_text:{
		fontSize:'18px',
		fontFamily:'Rajdhani',
		color:'#000'
	},
	tools_ip_text:{
		fontSize:'18px',
		fontFamily:'Rajdhani',
		color:'#000',
		marginTop:'10px'
	},
	tools_text_link:{
		fontSize:'18px',
		fontFamily:'Rajdhani',
		color:'#000',
		textDecoration:'underline'
	},
	link_text:{
		color:'#000'
	},
	saperator_image:{
		width:'50%',
		padding:'15px 0',
		margin:'0 auto'
	}
}));

function Tools() {
	seo_tags({
      title: 'Tools'
    });

	const classes = useStyles();
	const [clientIP, setclientIP] = useState(0);

	useEffect(() => {
		scroll_top();
		fetch(process.env.REACT_APP_API_DOMAIN+"tools")
		.then(res => res.json())
		.then(
			(result) => {
				setclientIP(result);
			},
			(error) => {
				console.log(error);
			}	
		)
	},[]);	

	return (
		<div className='toolWrap'>
			<Grid className="container_div maxWrapper" container px={5}>

				
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
						<CardMedia
							component="img"
							alt="Toots title"
							image="../page-headline/tools.png"
							title="Tools"
							className="page_header_image"
						/>
					</Grid>
				</Grow>

        		<Grow in={true}>
					<Grid className={classes.intro_para} item xs={12}>
						Over the years we have collected a range of tools which will help us both work more efficiently. Design Match Tool and Encrypted Transfer have been developed by our own team. Give them a try!
					</Grid>
				</Grow>
				
				<Grow in={true}>
                    <Grid className={classes.grid_item} item xs={12} md={4}>
						<Link href="https://www.encryptedtransfer.com/"  target="_blank" rel="noreferrer">
							<CardMedia
								component="img"
								alt="nda icons"
								image={"../tools-page/encrypted-trasfer-icon.png"}
								title="Encrypted transfer"
								className={classes.tools_image}
							/>
							<Typography className={classes.tools_text_link}>
								Encrypted Transfer
							</Typography>
						</Link>
					</Grid>
				</Grow>

				{ clientIP ?  
					<Grow in={true}>
						<Grid className={classes.grid_item} item xs={12} md={4}>
							<CardMedia
								component="img"
								alt="My ip"
								image={"../tools-page/my-ip.png"}
								title="My ip"
								className={classes.tools_image}
							/>
							<Typography className={classes.tools_ip_text}>
								Your IP is: {clientIP}
							</Typography>
						</Grid>
					</Grow>
				
				: null }
				

				<Grow in={true}>
                    <Grid className={classes.grid_item} item xs={12} md={4}>
						<Link className={classes.link_text} href="http://whatsmyudid.com/"  target="_blank" rel="noreferrer">
							<CardMedia
								component="img"
								alt="My udid"
								image={"../tools-page/udid.png"}
								title="My udid"
								className={classes.tools_image}
							/>
							<Typography className={classes.tools_text_link}>
								
									What's my UDID
							</Typography>
						</Link>
					</Grid>
				</Grow>

				<Grow in={true}>
                    <Grid className={classes.grid_item} item xs={12} md={4}>
						<Link href="https://www.dropbox.com"  target="_blank" rel="noreferrer">
							<CardMedia
								component="img"
								alt="nda icons"
								image={"../tools-page/dropbox-icon.png"}
								title="Dropbox"
								className={classes.tools_image}
							/>
						</Link>
						<Link href="https://www.dropbox.com"  target="_blank" rel="noreferrer">
							<Typography className={classes.tools_text_link}>
								Use Dropbox to share large files with us
							</Typography>
						</Link>
						<Typography className={classes.tools_text}>Our account name is jonny@sapnagroup.com</Typography>
					</Grid>
				</Grow>

				<Grow in={true}>
                    <Grid className={classes.grid_item} item xs={12} md={4}>
						<Link className={classes.link_text} href="../tools-page/Non_Disclosure_Agreement.doc"  target="_blank" rel="noreferrer">
							<CardMedia
								component="img"
								alt="nda icons"
								image={"../tools-page/nda-icon.png"}
								title="Non disclosure agreement"
								className={classes.tools_image}
							/>
							<Typography className={classes.tools_text_link}>
								Download Non Disclosure Agreement
							</Typography>


						</Link>
					</Grid>
				</Grow>

				<Grow in={true}>
                    <Grid className={classes.grid_item} item xs={12} md={4}>
						<Link href="/ftpsetup"  target="_blank" rel="noreferrer">
							<CardMedia
								component="img"
								alt="nda icons"
								image={"../tools-page/encrypted-icon.png"}
								title="Encrypted FTP(FTPS) setup"
								className={classes.tools_image}
							/>
							<Typography className={classes.tools_text_link}>
								Encrypted FTP(FTPS) setup
							</Typography>

						</Link>
					</Grid>
				</Grow>
			</Grid>
		</div>
	)
}

export default Tools
