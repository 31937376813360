import React, {  useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';

import Link from '@material-ui/core/Link';

import mixitup from 'mixitup';


const useStyles = makeStyles((theme) => ({
  grid_item: {
    // background:'#ebebeb',
    padding:'10px 15px'
  },
  team_image: {
    height: '200px',
    width:'200px',
    margin:'0 auto',
    border:'0px solid black',
    '&:hover': {
        scale:'1.1',
        '-webkit-transition':'all .3s',
        '-moz-transition': 'all .3s',
        'transition': 'all .3s'
    }
  },
  emp_name: {
    textAlign: 'center',
    fontSize: '21px;',
    fontFamily: 'Rajdhani',
    fontWeight: 'bold',
    paddingTop: '10px',
    margin:'0'
  },
  team_title: {
    textAlign: 'center',
    fontSize: '18px;',
  },
  container_div: {
    padding:'0 55px',
    border:'0px solid black'
  },
  grid_paper:{
    margin:'0 auto'
  },
  filter_container:{
    left: '0',
    padding: '0 0 20px',
    width: '100%',
    wordWrap: 'break-word',
	textAlign: 'center'
  }
}));

function TeamsGrid(props) {
    const classes = useStyles();

    let teamsProps = props.teamsData;

    useEffect(() => {
      var mixer = mixitup('#mixitup_container', {
          selectors: {
              target: '.mix'
          },
          animation: {
              duration: 500
          }
      });

      return () => {
        mixer.destroy();
      }
    },[]);


    return (
        <div>
            <Grid className={classes.container_div} container px={6}>
              <Grow in={true}>
                  <Grid className={classes.team_title} item xs={12}>
                    <CardMedia
                      component="img"
                      alt="Team title"
                      image="../page-headline/team.png"
                      title="Team title"
                      className="page_header_image"
                    />
                  </Grid>
                </Grow>

                <div className={classes.filter_container}>
                  <Link color="inherit" className="teams_filter_link" data-filter="all">
                    All
                  </Link>
                  <Link color="inherit" className="teams_filter_link_Accm" data-filter=".acc_mgmnt">
                    Account management
                  </Link>
                  <Link color="inherit" className="teams_filter_link" data-filter=".prog">
                    Programming
                  </Link>
                  <Link color="inherit" className="teams_filter_link" data-filter=".seo">
                    Digital marketing
                  </Link>
                  <Link color="inherit" className="teams_filter_link" data-filter=".server_admin">
                    Server admin
                  </Link>
                  <Link color="inherit" className="teams_filter_link" data-filter=".admin">
                    Administration
                  </Link>
				  <Link color="inherit" className="teams_filter_link" data-filter=".content">
                    Content creation
                  </Link>
                </div>
                
              
                <div id="mixitup_container" className="container">
                  {teamsProps.map((item,id) => (
                    <Grow key={id} in={true}>
                      <div className={item.teamClass+" mix"}>
                        <Grid className={classes.grid_item} item xs={12}>
                                <CardMedia
                                component="img"
                                alt={item.name}
                                height="50"
                                image={item.imgPath}
                                title={item.name}
                                className={classes.team_image}
                                />
                              
                                <Typography className={classes.emp_name}  gutterBottom variant="h5" component="h2">
                                    {item.name}
                                </Typography>
                        </Grid>

                      </div>
                    </Grow>
                  ))}
                </div>
            </Grid>
        </div>
    )
}

export default TeamsGrid
