import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import CardMedia from '@material-ui/core/CardMedia';

import {scroll_top,seo_tags} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px'
  },
  page_image: {
    height: 'auto',
    width:'100%',
    margin:'0 auto'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '22px;',
    fontFamily:'Rajdhani',
    color: '#000',
	padding:'20px 0'
  },
  container_div: {
    padding:'0 55px',
    border:'0px solid black'
  },
  ftpes_image_conn:{
	  width:'100%',
	  height:'auto',
	  padding:'10px 0 40px 0',
	  maxWidth:'550px',
	  margin:'0 auto'
  },
  ftpes_image:{
	  width:'100%',
	  height:'auto',
	  padding:'10px 0 40px 0',
	  maxWidth:'497px',
	  margin:'0 auto'
  }
}));


function FtpPage() {
	seo_tags({
      title: 'FTP (FTPS) SETUP'
    });

	const classes = useStyles();

	useEffect(() => {
		scroll_top();
	},[]);

	return (
		<div className="maxWrapper smallWidthPage">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
						<CardMedia
							component="img"
							alt="FTP (FTPS) SETUP"
							image="../page-headline/ftpes.png"
							title="FTP (FTPS) SETUP"
							className="page_header_image_height"
						/>
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
						Our servers support FTPS (Encrypted FTP) rather than plain FTP (unencrypted - the communication between the server and client is in clear text and can be easily intercepted and compromised). FTPS uses Transport Layer Security (TLS) / Secure Sockets Layer (SSL) connections to encrypt data transferred over FTP control and data connections. 
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
						To use FTP over TLS (FTPS) you need a TLS capable FTP client.There are many free FTPS clients available, Filezilla (https://filezilla-project.org/) being one of them.
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid item xs={12}>
						Instruction to use FTPS with Filezilla. If you are using the Quick connect dialogbox, prefix the hostname with ftpes://
					</Grid>
				</Grow>

				<Grow in={true}>
					<CardMedia
						component="img"
						alt="Toots title"
						image="../image_ftp.jpg"
						title="Ftpes image"
						className={classes.ftpes_image_conn}
					/>
				</Grow>

				<Grow in={true}>
					<Grid item xs={12}>
						If you are using the Site Manager, select ServerType as FTPES from the dropdown.
					</Grid>
				</Grow>

				<Grow in={true}>
					<CardMedia
						component="img"
						alt="Toots title"
						image="../ftp2.jpg"
						title="Ftpes image 2"
						className={classes.ftpes_image}
					/>
				</Grow>
			</Grid>
		</div>
	)
}

export default FtpPage
