import React, {  useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';

import {saperator_index,display_contactBubble,seo_tags,scroll_top} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
  nilesh_image: {
    width: 'auto',
	margin: '0 auto',
	maxWidth: '100%',
  },
  testimonial_image: {
    width:'auto',
	margin:'0 auto',
	padding:'0',
	maxWidth: '100%',
  },
  sign_image: {
    width:'200px',
	height:'auto',
	padding:'10px 0'
  },
  jobs_desc_grid:{
	  padding:'0px 10px 0 10px',
	  fontFamily:'inherit',
	  maxWidth: '300px',
	  margin: '0px auto 0px auto'
  },
  jobs_desc_grid_intro:{
	  padding:'5px 10px',
	  fontFamily:'inherit',
	  margin: '0px auto 0px auto'
  },
  jobs_para:{
	  fontFamily:'inherit',
      margin: '0 0 15px 0',
      fontSize: '18px',
  },
  empName:{
	paddingTop: '22px',  
  },
  jobs_para_first:{
	  fontFamily:'inherit'
  },
  nilesh_signature:{
	  textAlign:'left',
	  
  },
	footer_text:{
		fontFamily:'inherit'
	},
	testimonial_qualif:{
        fontFamily:'inherit',
        fontSize: 'inherit',
	},
	saperator_image:{
    width:'50%',
    padding:'15px 0',
    margin:'0 auto'
  }
}));

function Jobs() {
	seo_tags({
      title: 'Jobs'
    });

	const classes = useStyles();

	useEffect(() => {
		scroll_top();
		display_contactBubble();
	},[]);	

	const mailToCareers = () =>{
		let emailtoCareers = String.fromCharCode(99,97,114,101,101,114,115,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109);
		window.location = 'mailto:'+ emailtoCareers +'?';
	   
	}
	const mailToHr = () =>{
		let emailtoHr = String.fromCharCode(104,114,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109);
		window.location = 'mailto:'+ emailtoHr +'?';
	   
	}
   
	return (
		<div className ="jobsWrapper maxWrapper">
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grow in={true}>						
						<CardMedia
							component="img"
							alt="Jobs title"
							image="../page-headline/jobs.png"
							title="Jobs title"
							className="page_header_image"
						/>
					</Grow>
					
					<Box pb={4}>
						<Typography className={`${classes.jobs_para_first} topText`}>
							Hi. I am Nilesh, one of the promoters of sapnagroup. I thought I would personally pen down a few lines about the company and what it holds in store for you, should you join it.
						</Typography>
					</Box>
				</Grid>
				
				
				<Grid item xs={12} md={2}>
					<CardMedia
						component="img"
						alt="Nilesh Nayak"
						image='../jobs/nilesh.png'
						title="Nilesh Nayak"
						className={classes.nilesh_image}
					/>
				</Grid>
				<Grid item xs={12} md={6} align={'left'}>
					<Box className='LHSText' pr={4} pl={3}>
						<Typography className={classes.jobs_para}>
							I would request you not to look at this company as a run of the mill, small time IT shop similar to numerous others located in Goa. A software company in Goa possibly gives the impression of a small time hardware/software unit doing Wordpress websites and small web applications for local industries. I think we need to get out of this stereotype. The Internet makes it possible to develop world-class software from anywhere on the globe. Before 2000, a US citizen would have viewed an IT company in India with the same stereotype; today he/she views an Indian as a czar of the IT world.
						</Typography>
						<Typography className={classes.jobs_para}>
							For a start, I have given up plum jobs at good companies (I have done my BTech in IIT Chennai, followed by an MBA from XLRI) in metros to come back to Goa and start this company. The idea was to develop skills in a very niche area. The focus has always been on international clients. Today 15 years down the line, I think we have made substantial progress towards our goal.
						</Typography>
						<Typography className={classes.jobs_para}>
							Our skillset in areas like PHP, Mysql, Ajax, Javascript and HTML are possibly as good as the best in this field. We have developed mobile apps for the best and we even audit client applications for vulnerabilities. Our server team today maintains more than 10 servers some of which are credit card compliant and host critical sites of our clients. Our strong base of customers comprises blue chip companies, award winning start ups, high end technology companies and many others. We develop software using Agile and use the latest tools like GIT versioning system, JIRA and Slack for our project management.
						</Typography>
						<Typography className={classes.jobs_para}>
							Above all, we've built a team of over 55 people here in India who ensure that clients across the globe are kept happy. Some of my colleagues possibly have such a good understanding of technology that they can put European programmers to shame. And mind you, all of them are like you, from Goa, from colleges like Xavier's, Dhempe, GEC, PCCE, etc. They believed that world class work could be done sitting in Goa and you needn't necessarily be based in Bangalore, Noida or Pune or even Silicon Valley.
						</Typography>
						<Typography className={classes.jobs_para}>
							To take this growth further, we require talented people who can take responsibility and challenge and deliver. We are working on the latest technologies and challenge will never be an issue. You will not be another face in the crowd (like typical big software companies); you will have enough and more responsibility to ensure that your performance stands out.
						</Typography>
						<Typography className={classes.jobs_para}>
							Ideally, we are looking for people with experience in core PHP, Laravel, React, Angular, Linux server administration but someone who's worked on other web technologies like ASP, .NET, JSP etc. can also be considered. App developers with experience in iOS and Android or hybrid frameworks like Ionic and React Native are welcome and so also anyone who has worked on Internet security. Worst case scenario, if you don't have any experience with respect to the web but are still very much interested, please send in your resume, we will definitely have a look and get back to you.
						</Typography>
						<Typography className={classes.jobs_para}>
							If you would like to join this set of enterprising individuals, mail your resume to <span onClick={() => mailToCareers()} style={{textDecoration:'underline', cursor:'pointer'}}>careers@sapnagroup.com</span>.
						</Typography>
						<Typography className={classes.jobs_para}>
							Should you require any other information about the company or job, feel free to email us at <span onClick={() => mailToHr()} style={{textDecoration:'underline',cursor:'pointer'}}>hr@sapnagroup.com</span> and we will revert back to you.
						</Typography>
						
						<div className="salutationDiv">
							<Typography className={classes.jobs_para}>
								Regards
							</Typography>
							<CardMedia
								component="img"
								alt="Nilesh Nayak"
								image='../jobs/sign-3.png'
								title="Nilesh Nayak"
								className={classes.sign_image}
							/>
							<Typography className={classes.footer_text}>
								Nilesh Nayak
							</Typography>
							<Typography className={classes.footer_text}>
								Director
							</Typography>
						</div>
					</Box>
				</Grid>

				<Grid item xs={12} md={4} align={'left'} className="colleagues">
				
					{/* Prasad */}
					<Grid container spacing={3}>
						<CardMedia
							component="img"
							alt="Sapeartor image"
							image={"../divider-images/divider"+saperator_index()+".png"}
							title="saperator line"
							className={`${classes.saperator_image} seperatorImg`}
						/>
						
						<Grid item xs={12} md={5} >
							<CardMedia
								component="img"
								alt="Prasad Gawas"
								image='../jobs/prasad.png'
								title="Prasad Gawas"
								className={classes.testimonial_image}
							/>
						</Grid>
						<Grid item xs={12} md={7} >
							<Typography className={`${classes.testimonial_qualif} ${classes.empName}`}>Prasad Gawas</Typography>
							<Typography className={classes.testimonial_qualif}>Project Leader</Typography>
							<Typography className={classes.testimonial_qualif}>BCA</Typography>
							<Typography className={classes.testimonial_qualif}>MES College, 2008</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.jobs_para}>
								"The company has always provided me a chance to innovate and recognised my innovations. The kind of work done by us in web security, native and hybrid mobile apps as also cybersecurity has been a great learning experience."
							</Typography>
							
							<Typography className={classes.jobs_para}>
								Prasad has been with the company for nearly 15 years and has been involved in writing complex code for web applications and mobile apps. His key work in applications like MyFamilyCare (<a href="https://www.myfamilycare.co.uk" target="_blank" rel="noopener noreferrer">www.myfamilycare.co.uk</a>) and security audits for our websites have won him praise from the clients.
							</Typography>
							<br />
							<br />
							<br />
						</Grid>
					</Grid>
					
					{/* Madura */}
					<Grid container spacing={3}>
						<CardMedia
							component="img"
							alt="Sapeartor image"
							image={"../divider-images/divider"+saperator_index()+".png"}
							title="saperator line"
							className={`${classes.saperator_image} seperatorImg`}
						/>
						<Grid item xs={12} md={5} >
							<CardMedia
								component="img"
								alt="Madura"
								image='../jobs/madura.png'
								title="Madura"
								className={classes.testimonial_image}
							/>
						</Grid>
						<Grid item xs={12} md={7} >
							<Typography className={`${classes.testimonial_qualif} ${classes.empName}`}>Madura Sardesai</Typography>
							<Typography className={classes.testimonial_qualif}>Project Leader</Typography>
							<Typography className={classes.testimonial_qualif}>MSc IT</Typography>
							<Typography className={classes.testimonial_qualif}>Chowgule College 2005</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.jobs_para}>
								"It feels great to be dealing directly with European client and using contemporary technologies. I have done work in advanced PHP, SSO, Javascript and so on, to come up with wonderful applications for our clients."
							</Typography>
							<Typography className={classes.jobs_para}>
								Madura joined the company straight after her college. She heads a team for one of our key clients in the UK. Her work involves development of a custom built Learning Management System used by employees of large multinational companies across the globe.
							</Typography>
							<br />
							<br />
							<br />
						</Grid>
					</Grid>
					
					{/* Pallavi */}
					<Grid container spacing={3}>
						<CardMedia
							component="img"
							alt="Sapeartor image"
							image={"../divider-images/divider"+saperator_index()+".png"}
							title="saperator line"
							className={`${classes.saperator_image} seperatorImg`}
						/>
						<Grid item xs={12} md={5} >
							<CardMedia
								component="img"
								alt="Pallavi"
								image='../jobs/pallavi.png'
								title="Pallavi"
								className={classes.testimonial_image}
							/>
						</Grid>
						<Grid item xs={12} md={7} >
							<Typography className={`${classes.testimonial_qualif} ${classes.empName}`}>Pallavi Borkar</Typography>
							<Typography className={classes.testimonial_qualif}>Senior Programmer</Typography>
							<Typography className={classes.testimonial_qualif}>BE in IT</Typography>
							<Typography className={classes.testimonial_qualif}>PCCE 2006</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.jobs_para}>
								"In the last 16 years here, I have been learning new things everyday right from Agile to fixing SQL injections. It has been a satisfying experience to put all my skills to work."
							</Typography>
							<Typography className={classes.jobs_para}>
								Pallavi joined the company pretty soon after her graduation. She leads a full fledged team of nearly 15 members who service Bright Horizons (<a href="https://www.myfamilycare.co.uk" target="_blank" rel="noopener noreferrer">www.myfamilycare.co.uk</a>), the world's largest childcare company. It is a critical transactional application which is used 24x7 by employees of blue chip companies for their childcare requirements.
							</Typography>
							<br />
							<br />
							<br />
						</Grid>
					</Grid>
					
				</Grid>
			</Grid>
		</div>
	)
}

export default Jobs
