import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


function ToggleDarkMode() {
  // eslint-disable-next-line
  const [formats, setFormats] = useState(() => ['bold', 'italic']);

  const [darkMode, updateDarkMode] = useState(1);
  const [cookies, setCookie] = useCookies(['cookie_darkMode']);

  useEffect(() => {
    if(cookies.cookie_darkMode === 1){
      /* set dark mode by default */
      toggleDarkModeClass(1);
      updateDarkMode(1);
    }else{
      /* unset dark mode by default */
      toggleDarkModeClass(0);
      updateDarkMode(0);
    }
  },[]);

  function toggleDarkModeOff(){
      updateDarkMode(0);
      toggleDarkModeClass();
      setCookie('cookie_darkMode', 0, { path: '/' });
  }
  
  function toggleDarkModeClass(setClass){
    var root = document.getElementsByTagName( 'html' )[0];
        if(setClass){
            root.setAttribute( 'class', 'dark-mode' );
        }else{
            root.removeAttribute( 'class' );
        }
    }

  function toggleDarkModeOn(){
      updateDarkMode(1);
      toggleDarkModeClass(1);
      setCookie('cookie_darkMode', 1, { path: '/' });
  }

  const handleFormat = (event, newFormats) => {
    ( darkMode?toggleDarkModeOff():toggleDarkModeOn())
  };

  return (
    <ToggleButtonGroup size="small" value={formats} onChange={handleFormat} aria-label="text formatting" className="mode-btn">
      <ToggleButton value="bold" aria-label="bold">
        
        { darkMode?'Light mode':'Dark mode'}
        { darkMode?<Brightness5Icon />:<Brightness4Icon />}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default ToggleDarkMode