import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import {scroll_top,seo_tags} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px',
	textAlign:'left'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '18px;',
    fontFamily:'Rajdhani',
    color: '#000'
  },
  container_div: {
    padding:'0 40px',
    border:'0px solid black'
  },
  sub_title:{
	  fontSize:'18px',
	  fontFamily:'rajdhani',
	  padding:'20px 0'
  },
  sub_para:{
	  fontSize:'18px',
	  fontFamily:'rajdhani'
  },
  unordered_list:{
	  paddingLeft:'15px'
  },
  list_para:{
	margin:'0 0 15px',
  },

  list_heading:{	
	margin:'27px 0 15px',
  }
}));

function AmazonTerms() {
    seo_tags({
      title: 'Website Amazon Terms & Conditions'
    });

    const classes = useStyles();
    useEffect(() => {
		scroll_top();
	},[]);

    return (
        <div className ="maxWrapper">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
                        <h1>Terms and Conditions for sapnagroup Amazon Services</h1>
					</Grid>
				</Grow>


                <Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
					<ol>
						<li><p><a href="#scopOfService">Scope of Services</a> </p></li>
						<li><p><a href="#seviceFees">Service Fees</a> </p></li>
						<li><p><a href="#clinetResposnisbilities">Client Responsibilities</a> </p></li>
						<li><p><a href="#confidential">Confidentiality</a> </p></li>
						<li><p><a href="#IntellectualProperty">Intellectual Property</a> </p></li>
						<li><p><a href="#LimitationOfLiability">Limitation of Liability</a> </p></li>
						<li><p><a href="#Termination">Termination</a> </p></li>
						<li><p><a href="#governingLaw">Governing Law</a> </p></li>
						<li><p><a href="#entireAgreement">Entire Agreement</a> </p></li>
						<li><p><a href="#Amendments">Amendments</a> </p></li>
						<li><p><a href="#Notices">Notices</a> </p></li>
						<li><p><a href="#clientAcceptance">Client Acceptance</a> </p></li>
					</ol>

						<ol><span name="projectTerms">&nbsp;</span>
							<li><h4  className={classes.list_heading}  id="scopOfService">Scope of Services</h4>
								<p className={classes.list_para}>
									1.1. sapnagroup Ltd.  agrees to provide Amazon services as outlined in the service agreement.
								</p>
								<p className={classes.list_para}>
									1.2. The specific services to be provided will be detailed in a separate service agreement or statement of work (“SOW”).
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="seviceFees">Service Fees</h4>
								<p className={classes.list_para}>
								2.1. The Client agrees to pay the Service Provider the fees as outlined in the SOW. 
								</p>
								<p className={classes.list_para}>
								2.2. Payment terms and conditions will be specified in the SOW.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="clinetResposnisbilities">Client Responsibilities</h4>
								<p className={classes.list_para}>
								3.1. The Client agrees to provide all necessary information, access, and cooperation required for the provision of services. 
								</p>
								<p className={classes.list_para}>
								3.2. The Client is responsible for complying with all Amazon policies and guidelines related to their account and products.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="Confidentiality">Confidentiality </h4>
								<p className={classes.list_para}>
									4.1. Both parties agree to maintain the confidentiality of any proprietary or sensitive information disclosed during the course of the engagement. 
								</p>
								<p className={classes.list_para}>
									4.2. This includes but is not limited to business strategies, customer data, and trade secrets.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="IntellectualProperty">Intellectual Property</h4>
								<p className={classes.list_para}>
									5.1. All intellectual property rights developed or used in the provision of services shall remain the property of the respective party.
								</p>
								<p className={classes.list_para}>
									5.2. The Client grants the Service Provider a non-exclusive, royalty-free license to use the Client’s intellectual property solely for the purpose of providing the agreed-upon services.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="LimitationOfLiability">Limitation of Liability</h4>
								<p className={classes.list_para}>
									6.1. The Service Provider shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or related to the services provided. 
								</p>
								<p className={classes.list_para}>
									6.2. The total liability of the Service Provider, whether in contract, warranty, tort (including negligence), or otherwise, shall not exceed the total fees paid by the Client for the services.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="Termination">Termination</h4>
								<p className={classes.list_para}>
									7.1. Either party may terminate the engagement upon written notice if the other party breaches any material term of the agreement 
								</p>
								<p className={classes.list_para}>
								7.2. Upon termination, the Client shall pay any outstanding fees owed to the Service Provider for services rendered up to the date of termination.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="governingLaw">Governing Law</h4>
								<p className={classes.list_para}>
									8.1. This agreement shall be governed by and construed in accordance with the laws of England.Jurisdiction. 
								</p>
								<p className={classes.list_para}>
									8.2. Any disputes arising out of or relating to this agreement shall be resolved through arbitration.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="entireAgreement">Entire Agreement</h4>
								<p className={classes.list_para}>
									9.1. This agreement, including any SOWs or attachments, constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings, whether written or oral.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="Amendments">Amendments</h4>
								<p className={classes.list_para}>
									10.1. Any amendments to this agreement must be made in writing and signed by both parties.
								</p>
							</li>


							<li><h4  className={classes.list_heading}  id="Notices">Notices: </h4>
								<p className={classes.list_para}>
									11.1. Any notices required or permitted under this agreement shall be in writing and delivered to the addresses specified by the parties.
								</p>
							</li>

							<li><h4  className={classes.list_heading}  id="clientAcceptance">Client Acceptance</h4>
								<p className={classes.list_para}>
									12.1. By approving the first estimate with a reference to these terms & conditions, the Client acknowledges that they have read, understood, and agree to be bound by the terms and conditions of this agreement.
								</p>
							</li>						
						</ol>
					</Grid>
				</Grow>
            </Grid>
        </div>
    )
}
export default AmazonTerms