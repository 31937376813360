
import React, {  useEffect } from 'react';
import '../css/base.scss';

import AnimatedLinksObjs from "../js/animatedLink";

import { Link } from "react-router-dom";
import ToggleDarkMode from './ToggleDarkMode';

function DistortionEffect() {

    useEffect(() => {
        /* Distortion effect initalized */
        [...document.querySelectorAll('a.menu__link')].forEach((el) => {
            //const elPosition = [...el.parentNode.children].indexOf(el);
            const fxObj = AnimatedLinksObjs[0];
            fxObj && new fxObj(el);
        });

        var menu__links = document.getElementsByClassName("menu__link");
        for (var i = 0; i < menu__links.length; i++) {
            menu__links[i].addEventListener('click', close_navi, false);
        }

        function close_navi(){
            var mobile = document.getElementById('site_container');
            mobile.classList.toggle('navigation');
        }
    }, []);

    return (
    <div className="distortion-menu">
        <svg className="hidden">
            <defs>
                <filter id="filter-1">
                    <feTurbulence type="fractalNoise" baseFrequency="0" numOctaves="1" result="warp" />
                    <feOffset dx="-90" result="warpOffset" />
                    <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="30" in="SourceGraphic" in2="warpOffset" />
                </filter>
            </defs>
        </svg>

        <ToggleDarkMode/>

        <nav className="menu menu--twolines">
            <Link to="/" className="menu__link">Home</Link>
            {/* <a class="menu__link" href="/services">Services</a> */}

            <Link to="/news" className="menu__link">News</Link>
            <Link to="/about" className="menu__link">About</Link>
            <Link to="/teams" className="menu__link">Team</Link>
            {/*<Link to="/academy" className="menu__link">Academy</Link>*/}
            <Link to="/jobs-page" className="menu__link">Jobs</Link>
            <Link to="/tools" className="menu__link">Tools</Link>
            <Link to="/portfolio/all" className="menu__link">Portfolio</Link>
            <Link to="/contact" className="menu__link">Contact</Link>

            {/* <a class="menu__link" href="/news">News</a>
            <a class="menu__link" href="/about">About</a>
            <a class="menu__link" href="/teams">Team</a>
            <a class="menu__link" href="/academy">Academy</a>
            <a class="menu__link" href="/jobs-page">Jobs</a> */}
            {/* <a class="menu__link" href="#">Where is Jonny</a> */}
            {/* <a class="menu__link" href="#">Bank Holidays</a> */}
            {/* <a class="menu__link" href="/tools">Tools</a>
            <a class="menu__link" href="/contact">Contact</a> */}
        </nav>
    </div>
    )
}

export default DistortionEffect
