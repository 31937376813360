import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import CardMedia from '@material-ui/core/CardMedia';
import {scroll_top,seo_tags} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '22px;',
    fontFamily:'Rajdhani',
    color: '#000',
	padding:'20px 0'
  },
  container_div: {
    padding:'0 55px',
    border:'0px solid black'
  },
  padded_para_left: {
    paddingBottom:'30px',
	textAlign:'left'
  },
}));

function SecurityPolicy() {
    seo_tags({
      title: 'Security Policy'
    });

	const classes = useStyles();

	useEffect(() => {
		scroll_top();
	},[]);

	return (
		<div className ="maxWrapper">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
						<CardMedia
                            component="img"
                            alt="Security Policy"
                            image="../page-headline/security-policy.png"
                            title="Security Policy"
                            className="page_header_image_height"
                        />
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
						Given the nature of the web and the importance of data sensitivity of our client data we at sapnagroup try to adhere to the high levels of security for our systems and procedures. Highlighted below are the security features that we follow. Please note these are generic policies, and policies specific to a project/client may differ.<br></br>Table of Contents:
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para_left} item xs={12}>
						<ol>
							<li><p><a href="#server">SERVER / DATABASE.</a> </p></li>
							<li><p><a href="#login">LOGIN PROCESS</a> </p></li>
							<li><p><a href="#exchange">INFORMATION EXCHANGE</a> </p></li>
							<li><p><a href="#scripting">SCRIPTING</a> </p></li>
							<li><p><a href="#backup">BACKUP SYSTEM</a> </p></li>
							<li><p><a href="#alert">ALERT SYSTEM</a> </p></li>
							<li><p><a href="#website">WEBSITE - REASONS FOR WEBSITES BEING DOWN</a> </p></li>
							<li><p><a href="#solutions">SOLUTIONS TO WEBSITES BEING DOWN</a> </p></li>
							<li><p><a href="#security">SECURITY MEASURES IMPLEMENTED IN OUR PROGRAMMING OFFICE IN INDIA</a></p></li>
							<li><p><a href="#glossary">GLOSSARY</a></p></li>
						</ol>
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid id="server" className={classes.page_title} item xs={12}>
						<ol>
                    <br /><span id="server" >&nbsp;</span>
                    <li>
                        <h4>SERVER / DATABASE</h4>
                        <ul>
                            <li>
                                <p id="#server"><b>SSH</b><br />Only one&nbsp;SSH account opened for server management</p>
                            </li>
                            <li>
                                <p><b><span id="ftpestop"></span>FTPES</b> <br />Only FTPES account opened which allows transfers of project related files.</p>
                            </li>
                            <li>
                                <p><b><span id="bkptop"></span>Backups</b> <br />Weekly full backups, daily incremental backups of files and database, both on the main and mirror server. All backups on external servers (eg Amazon S3 etc) encrypted using GPG.&nbsp;</p>
                            </li>
                            <li>
                                <p><b><span id="phptop"></span>PHPMyadmin access</b> <br />On a more complicated URL rather than /phpmyadmin. This is also over&nbsp;HTTPS (SSL) to ensure security.</p>
                            </li>
                            <li>
                                <p><b><span id="mirrortop"></span>Mirror server</b> <br />At alternate location in case of hardware failure. Switch to alternate server is a manual function. </p>
                            </li>
                            <li>
                                <p><b><span id="mysqltop"></span>MySQL replication</b><br />Happens to mirror server and ensures we have an exact copy of the database at any point.</p>
                            </li>
                            <li>
                                <p><b><span id="remotetop"></span>Remote backup of MySql database</b> <br />We use Amazon S3 service for backing up database on a remote location.</p>
                            </li>
                            <li>
                                <p><b><span id="onlytop"></span>Only local server has access to the database</b> <br />No server from outside can connect to the database.</p>
                            </li>
                            <li>
                                <p><b><span id="iptabtop"></span>Iptables Firewall</b> <br />Our servers are configured with iptables firewall, which is a packet filtering firewall. Packet filters work by examining the IP packets. This allows us to determine how packets to and from the server get processed. We can make choices based on</p>
                                <ul>
                                    <li>
                                        <p>source and destination</p>
                                    </li>
                                    <li>
                                        <p>port </p>
                                    </li>
                                    <li>
                                        <p>packet type </p>
                                    </li>
                                    <li>
                                        <p>protocol type</p>
                                    </li>
                                    <li>
                                        <p>user</p>
                                    </li>
                                    <li>
                                        <p>state of connection.</p>
                                    </li>
                                </ul>
                                <br />
                                <p>Only absolutely required services are available through the firewall, and checks for SYN Flood Protection, suspicious activity logging are also present. Special care has been taken to see that access to the database is made available only from the local system, and not from anywhere else on the Internet </p>
                            </li>
                            <li>
                                <p><b>Apache Mod Security</b> <br />This is an open source web application firewall. ModSecurity acts as an intrusion detection and prevention engine for web applications (or a web application firewall). Operating as an Apache web server module or standalone, the purpose of ModSecurity is to increase web application security, protecting web applications from known and unknown attacks.</p>
                            </li>
                            <li>
                                <p><b><span id="brute"></span>Brute force attack</b> <br />Both ssh and ftpes are protected by brute force protection. 7 successive wrong attempts would force the&nbsp;IP to be blocked for a day. </p>
                            </li>
                            <li>
                                <p><b><span id="dos"></span>DoS attack prevention</b> <br />A script on our system analysis client requests and delays/blocks any that it suspects as a DoS attack.</p>
                            </li>
                            <li>
                                <p><b>Regular security updates</b> <br />Our server admin team is subscribed to various internet security advisories like www.frsirt.com, isc.sans.org etc and immediately patch/update the system whenever any security vulnerability is known.</p>
                            </li>
                            <li>
                                <p><b>Reporting</b> <br />All web activity (with details like how many people visited the site, which page the viewed the most, 404 document errors, which sites connect to our site i.e. referrers) are logged and are available to the customers under a password protected page for review. <br />At the same time all system logs are continuously analysed for problems/issues that might occur, specifically logs which indicate failed attempts of login (hack attempts) and email failures (problems in sending emails).<br />Our team is constantly in search and scripting of better method of monitoring and reporting, like services monitoring and auto restart of these in case of failures.<br />Administration is not a one time setup. It involves constant upgrade of services, tools, patches which help maintain better health conditions of servers.</p>
                            </li>
                            <li>
                                <p><b>Monitoring system</b> <br />This checks for services like FTPES, web, database etc on a regular basis (every 5 mins by default) and immediately reports any problems to the server admin team. </p>
                            </li>
                        </ul>
                        <br /><span id="login"></span>
                    </li>
                    <li>
                        <h4>LOGIN PROCESS</h4>
                        <ul>
                            <li>
                                <p>Forced SSL3 protocol to ensure login details are encrypted over the internet</p>
                            </li>
                            <li>
                                <p>Captcha solution for creating logins which are valid for 90 seconds.</p>
                            </li>
                            <li>
                                <p>Passwords as SHA1 or SHA256 with salt </p>
                            </li>
                            <li>
                                <p>Case sensitive user names and passwords</p>
                                <br />
                            </li>
                        </ul>
                        <br /><span id="exchange"></span>
                    </li>
                    <li>
                        <h4>INFORMATION EXCHANGE</h4>
                        <ul>
                            <li>
                                <p>SSL protocol for all restricted areas to ensure all information exchanged is encrypted.</p>
                            </li>
                            <li>
                                <p>Only FTPES account opened which allows transfers of project related files.</p>
                            </li>
                            <li>
                                <p>PHPMyadmin access on a more complicated URL rather than /phpmyadmin. This is also over https (ssl) to ensure security.</p>
                            </li>
                            <li>
                                <p>Session stealing protection, and a variety of other steps (including predefined session hack) to ensure your session is safe.</p>
                            </li>
                            <li>
                                <p>Session ID information is forced to be sent via cookies rather then url-rewrite </p>
                            </li>
                        </ul>
                        <br /><span id="scripting"></span>
                    </li>
                    <li>
                        <h4>SCRIPTING</h4>
                        <br />
                        <p>Our scripts take into account all possible security related issues during the programming process. The scripts are tested for bugs wherein anybody with access to one area cannot get into another protected area. We also try to prevent spam hacking by effective use of captcha. Issues like SQL injection and session stealing are also taken into account during the coding process. Some things which we do as a standard coding practice</p>
                        <ul>
                            <li>
                                <p>PHP Information non disclosure - By ensuring removal of all files which run commands like phpinfo() which give the settings information.</p>
                            </li>
                            <li>
                                <p>Administration panel on a more complicated URL rather than "/admin".</p>
                            </li>
                            <li>
                                <p>Implementation of CAPTCHA for admin login.</p>
                            </li>
                        </ul>
                        <span id="backup"></span><br />
                    </li>
                    <li>
                        <h4>BACKUP SYSTEM</h4>
                        <ul>
                            <li>
                                <b>On site backups</b> <br />
                                <p>Our backup strategy is as follows</p>
                                <ul>
                                    <li>
                                        <p>Weekly full backups </p>
                                    </li>
                                    <li>
                                        <p>Daily incremental backups (backup only of files which are modified or new)</p>
                                    </li>
                                    <li>
                                        <p>Daily MySQL backups </p>
                                    </li>
                                    <li>
                                        <p>MySQL replication (the mirror server always has the latest database available at any point) </p>
                                    </li>
                                    <li>
                                        <p>Backups are stored on both servers </p>
                                    </li>
                                    <li>
                                        <p>All our backups are encrypted for security </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p><b>Mirror server</b> <br />Even though a good backup system is in place, for a smooth operation, &nbsp;a quick recovery is still essential. Also if the main server totally fails (hardware failure) there could be a considerable delay making the&nbsp;website to operate again. To provide a quick recovery in such cases we have a&nbsp;mirror server which is an almost exact copy of the main server (database is synced and files are maximum 1 day old).&nbsp;The switch to the mirror server in case of problems&nbsp;with the main server is a manual process. </p>
                            </li>
                            <li>
                                <p><b>Off site backups</b> <br />Daily MySQL backups are encrypted and sent to an offsite server. They include only the crucial parts of the data, e.g. no web statistics. They are for worst case scenario that all our servers are not accessible anymore at all and the website needs to be set up on a completely new server.</p>
                                <br />
                            </li>
                        </ul>
                        <span id="alert"></span><br />
                    </li>
                    <li>
                        <h4>ALERT SYSTEM</h4>
                        <ul>
                            <li>
                                <p><b>Notification that the Website is down</b> <br />The main issue is to be notified quickly if the website is down. There are server monitoring tools but even if the server is still running the website can be down, e.g. if the database crashed or if the server is overloaded. A remote server monitors the health of our live server very regularly. In case, the script feels that the live server is down, it will send an SMS to our server admin team. If there is a problem on your server we will notify you immediately about this via an sms (if you opt for that option) and give you the details including if possible how much time it will take to rectify.&nbsp;</p>
                            </li>
                            <li>
                                <p><b>Emergency contacts</b> <br />Our server admin team is available for any emergencies and their mobile numbers are given to clients for such emergencies.</p>
                            </li>
                        </ul>
                        <br /><span id="website"></span>
                    </li>
                    <li>
                        <h4>WEBSITE - REASONS FOR WEBSITES BEING DOWN</h4>
                        <ul>
                            <li>
                                <p><b>Bug in the website software</b> <br />A bug in the website software can bring an entire website down e.g. a syntax error in PHP language, logic issue which takes a script in an endless loop. Proper test by programmers before they take the changes live, and after taking it live minimises this risk. </p>
                            </li>
                            <li>
                                <p><b>User error</b> <br />Users of the system (administrators etc) can by mistake delete entries using the CMS. This would cause important pages to go offline or errors appear on your Website. A proper backup solution will ensure a good recovery from this problem.</p>
                            </li>
                            <li>
                                <p><b>Hardware failure</b> <br />Hardware can fail, e.g.&nbsp;the hard-disk can go corrupt, the network card might stop working, etc. This will cause your entire website to fail.</p>
                            </li>
                            <li>
                                <p><b>DNS problems </b><br />DNS (Domain Name Server/Service/System) basically links your domain name e.g. yourdomain.com with your IP address (e.g. 192.168.0.1), so that it can forward all requests to this IP. If this fails, the internet would not know where to forward any users request for your domain. </p>
                            </li>
                            <li>
                                <p><b>Hack attack/malicious script attacks</b> <br />Hack attacks happen everywhere. Every machine is prone to it. Either a user is trying to get into the system by trying various methods, or running a set of tools/script which automatically try different username password combinations, or bots automatically try different methods to get even a small access to run scripts which utilise all the bandwidth and processing power of the system causing a DOS (Denial of service).</p>
                            </li>
                            <li>
                                <p><b>Network problems with the ISP</b> <br />There could be internal network problem issue with the ISP wherein the requests get forwarded correctly via the internet, but once they come to the ISP they fail to resolve. </p>
                            </li>
                            <li>
                                <p><b>Too much load on the server</b> <br />Too many users using the Website or heavy scripts being called too many times can increase the server load which then would fail to service any new requests, making the website browsing experience unsatisfactory.</p>
                            </li>
                            <li>
                                <p><b>OS crashed </b><br />The operating system can crash. Although Debian (the OS on our server) has a reputation of being extremely stable, too many factors play a role in causing an OS to crash e.g. file corruption. Such a failure could bring the website down. </p>
                            </li>
                            <li>
                                <p><b>Internet network problems (e.g. overloaded internet as after the 7/7 terrorist attacks in London) </b><br />Internet problems cannot be ruled out. Although rare such problems do occur when too many users use the system. This could cause the website to be not reachable and hence appear offline.</p>
                            </li>
                            <li>
                                <p><b>Phishing</b><br />The act of sending an e-mail to a user falsely claiming to be an established legitimate enterprise in an attempt to scam the user into surrendering private information that will be used for identity theft. If this is done on our server then the legitimate enterprise can contact the ISP (Hetzner) to request the website to be shutdown.<br />Phishing can occur only if they have access to our server e.g. ftp access, these are got using social engineering methods or via brute force attacks. We use fail2ban to prevent brute force.</p>
                            </li>
                        </ul>
                        <span id="solutions"></span><br />
                    </li>
                    <li>
                        <h4>SOLUTIONS TO WEBSITES BEING DOWN</h4>
                        <br />
                        <p>The response time really depends on the task and the time of day. The time it takes to fix depends on the issue. We have outlined different scenarios in the document attached. The time mentioned below are guidelines, and the exact time to resolve a situation will be notified by the Supplier when any of the below listed steps are to be taken.</p>
                        <ul>
                            <li>
                                <p><b>Remote server/service reset </b><br />Server reset basically means we restart the server/ service which is required when its memory contents go corrupt at times.</p>
                            </li>
                            <li>
                                <p><b>Remote hardware reset </b><br />Hardware reset is a forceful reset of the server. Useful when we cannot get access to the system to restart a service etc.</p>
                            </li>
                            <li>
                                <p><b>Switching to mirror server </b><br />We keep a mirrored server which at any point has all the files (mirrored daily) and the latest database at any point. We can switch over to this mirrored server if the main server has problems which could take a longer time to fix. Since this server is in a different building for safety purpose (fire in one building ensures data on the other is safe) we cannot dynamically switch IPs, but would rather have to update the A record and MX records which can take upto 48 hours to resolve.</p>
                            </li>
                            <li>
                                <p><b>Replacing hardware </b><br />Our ISP's data centre is available for any such requirement even out of office hours.</p>
                            </li>
                            <li>
                                <p><b>Fixing programming bugs </b><br />Programming bugs need to be reported to the concerned party / programmer. in case a script is causing an endless loop which causes a system failure we can terminate / disable such a script till the time the concerned programmer fixes it.</p>
                            </li>
                            <li>
                                <p><b>Restoring backup </b><br />Backup restoration could be for a single file, group of files, the entire domain files, entire server files, and/or database.</p>
                            </li>
                            <li>
                                <p><b>Responding to a hacker attack </b><br />Need to identify the hacker script, understand what it does, which files it affects, understand how it entered the system.</p>
                            </li>
                            <li>
                                <p><b>Responding to DNS problems </b><br />If the DNS issue is in our hands (like a wrong MX entry etc) and the DNS setting for the domain is under our control we should be able to change this ourselves. If not we will report to the responsible party.</p>
                            </li>
                            <li>
                                <p><b>Responding to heavy load </b><br />Increase in load would mean we need to cater to this heavy demand by increasing system performance which could mean changing system configurations or updating the hardware like RAM.</p>
                            </li>
                            <li>
                                <p><b>Installing the website on a new server </b><br />Such setups take time. We have to configure the system, install the latest security updates, setup accounts FTPES, mail, system, setup backup procedures, run services.</p>
                            </li>
                            <li>
                                <p><b>Fixing ISPs network problems </b><br />We are not able to influence this but you can assume that our ISP will work with the very highest priority to get any network issues fixed as this affects all their clients.</p>
                                <br />
                            </li>
                            <li>
                                <p><b>ISP shuts down the server because of issues like our hacked server attacking other servers in the network, request from a 3rd legitimate party to shut down our server, etc</b><br />Our ISP would inform well in advance in case a request has been made by some external party to shut down our server/domain, and give us time to resolve our problem (so far it does not affect their other systems) and get back to the 3rd party directly to explain our situation.</p>
                                <br />
                            </li>
                        </ul>
                        <br /><span id="security"></span>
                    </li>
                    <li>
                        <h4>SECURITY MEASURES IMPLEMENTED IN OUR PROGRAMMING OFFICE IN INDIA</h4>
                        <ul>
                            <li>
                                <p><b>PCs password protected at the BIOS level</b> <br />All systems at our office are password protected by enabling BIOS password, which is different for every system.</p>
                            </li>
                            <li>
                                <p><b>Sensitive information download</b><br />Programmers do not download any sensitive data to PC. </p>
                            </li>
                            <li>
                                <p><b>Access to the live websites</b> <br />Only senior developers have access to the live websites for development / maintenance purpose. They will only have access to websites there are working on. </p>
                            </li>
                            <li>
                                <p><b>Passwords</b> <br />All passwords are kept or distributed encrypted using Keepass / Axcrypt. All FTPES and database passwords generated are very randomised and substantially long so that someone can't easily remember them in memory. All server passwords are stored in an encrypted application and accessible to only 3 people in the organisation. Regular changing of admin passwords which are used by programmers.</p>
                            </li>
                            <li>
                                <p><b>Email</b> <br />Full control of all employee email accounts</p>
                            </li>
                            <li>
                                <p><b>Exchange of sensitive information</b> <br />All sensitive information exchange within the company and across clients happens using encrypted software like Keepass / Axcrypt or using http://www.encryptedtransfer.com which has been developed by us.</p>
                            </li>
                            <li>
                                <p><b>Access to work stations</b> <br />This is a risk which can arise in case a laptop is stolen / lost or somebody unauthorised tries to access data on a PC in the office. For this we put a bios password and a Windows password for all laptops / PCs used. We try to keep minimal sensitive data in unencrypted form on any desktop, laptop or office server. Most information is stored on our servers and even there, live database data or access is not stored unencrypted. For internal servers we have the same level of security as for our web servers (see above). All work stations have updated anti virus software and anti spyware applications installed. Only licensed software is used for development.</p>
                            </li>
                            <li>
                                <p><b>Systems acquisition</b> <br />Information systems acquisition through recognised and trusted vendors</p>
                            </li>
                            <li>
                                <p><b>Development server and internet access</b> <br />All development is done on 1-2 development servers which are located at the physical office premises. There are 2-3 ADSL lines for accessing internet. All these lines are routed through the development server which has a transparent proxy installed. All internet requests are therefore monitored. Also as a policy, we do not download sensitive data to our databases on the development server.Development server follows same security norm as the live server to prevent any hack attempts.</p>
                            </li>
                            <li>
                                <p><b>Vetting process for people applying for jobs </b><br />We make them go through 2 different written tests and an interview. We have a full fledged HR department which also does a reference check of all candidates and also scrutinises all their academic certificates for any fraud. Reference check done for all new recruits. Every employee signs a non disclosure agreement wherein he/she is expected to maintain the secrecy of all customer data.</p>
                            </li>
                            <li>
                                <p><b>Ex-employees</b> <br />When an employees quits the company, his email address is deleted so that he cannot access any of his old emails. We also change our access details on a regular basis so that a person cannot get into a system based on his/her old access.</p>
                            </li>
                        </ul>
                        <span id="glossary"></span><br />
                    </li>
                    <li>
                        <h4>GLOSSARY<br /><br /></h4>
                        <ol>
                            <li>
                                <p><b><span id="#sshtop">SSH:</span></b> Short for Secure Shell provides strong authentication and secure communications over insecure channels. It is a replacement for rlogin, rsh, rcp, and rdist. SSH protects a network from attacks such as IP spoofing, IP source routing, and DNS spoofing. An attacker who has managed to take over a network can only force ssh to disconnect. He or she cannot play back the traffic or hijack the connection when encryption is enabled. When using ssh's slogin (instead of rlogin) the entire login session, including transmission of password, is encrypted; therefore it is almost impossible for an outsider to collect passwords.</p>
                            </li>
                            <li>
                                <p><b><span id="#bkptop">Incremental Backups:</span></b> Backups taken after the main full backup is over. It contains only those entries (e.g. images, code files) which have added/changed after the full backup was taken.</p>
                            </li>
                            <li>
                                <p><b><span id="#phptop">SSL:</span></b> Short for Secure Sockets Layer, a protocol developed by Netscape for transmitting private documents via the Internet. SSL uses a cryptographic system that uses two keys to encrypt data - a public key known to everyone and a private or secret key known only to the recipient of the message. This helps to protect sensitive data including passwords.</p>
                            </li>
                            <li>
                                <p><b><span id="#login">Captcha:</span></b>A technique for differentiating humans from machines. A captcha method presents a problem that's relatively easy for humans to solve, but difficult or impossible for computers to solve at this time. One common captcha method presents users with an image containing some embedded text. Users must decipher the text and enter that along with the submitted login or user-account creation form.</p>
                            </li>
                            <li>
                                <p><b><span id="#exchange">Session stealing:</span></b>Anyone who gets to know the current session id for any users (which itself is next to impossible if the server is setup well) will be able to access information. To prevent this session ids are cross checked with IP addresses (which are obtained at the time of login) to ensure security. </p>
                            </li>
                            <li>
                                <p><b><span id="#login">MD5:</span></b> In cryptography, MD5 (Message-Digest algorithm 5) is a widely-used cryptographic hash function with a 128-bit hash value. As an Internet standard (RFC 1321), MD5 has been employed in a wide variety of security applications.</p>
                            </li>
                            <li>
                                <p><b><span id="#login">SHA1:</span></b> The US Secure Hash Algorithm takes a message of less than 264 bits in length and produces a 160-bit message digest designed so that it is computationally very expensive to find a text string that matches a given hash </p>
                            </li>
                            <li>
                                <p><b><span id="#phptop">HTTPS:</span></b> (Hypertext Transport Protocol Secure) the protocol for accessing a secure Web server. Using HTTPS in the URL instead of HTTP directs the message to a secure port number rather than the default Web port number of 80. The session is then managed by a security protocol such as SSL3. </p>
                            </li>
                            <li>
                                <p><b><span id="#ftpestop">FTPES:</span></b> FTPES (commonly referred to as FTP/SSL) is a name used to encompass the way in which FTP software can perform secure file transfers. Each way involves the use of a SSL/TLS layer below the standard FTP protocol to encrypt the control and/or data channels. </p>
                            </li>
                            <li>
                                <p><b><span id="#dos">DoS attacks:</span></b> An attack to make the computer resources unavailable to genuine users. A common attack would be to make high saturated requests to the computer so that other legitimate requests suffer (denied or rendered slow) as the computer is busy responding to the attackers request. </p>
                            </li>
                            <li>
                                <p><b><span id="#brute">Brute force attack:</span></b> An attack in which a large number of combinations are tried in an attempt to make one of them successful. E.g. for a username password box, an attacker might keep on trying different combination hoping one of them will work. Automated attempts at brute force help reduce the time duration required.</p>
                            </li>
                        </ol>
                    </li>
                </ol>
					</Grid>
				</Grow>

				
			</Grid>
		</div>
	)
}

export default SecurityPolicy
