import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';

import {scroll_top,seo_tags} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '22px;',
    fontFamily:'Rajdhani',
    color: '#000',
	padding:'20px 0'
  },
  container_div: {
    padding:'0 55px',
    border:'0px solid black'
  },
  padded_para_left: {
    paddingBottom:'30px',
	textAlign:'left'
  },
}));

function Tnc() {
    seo_tags({
      title: 'Terms & Conditions'
    });

	const classes = useStyles();

	useEffect(() => {
		scroll_top();
	},[]);

	return (
		<div className ="maxWrapper">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
                            <div className='heading'>
								<h1>Terms & Conditions</h1>
							</div>
                        
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
						Please refer to the links below for the terms and conditions for different services.
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para_left} item xs={12}>
						<ol>
							<li><p><a href="http://www.sapnagroup.com/development-terms">Website Development Terms Conditions</a> </p></li>
							<li><p><a href="http://www.sapnagroup.com/hosting-terms">Hosting Terms & Conditions</a> </p></li>
							<li><p><a href="https://www.sapnagroup.com/digital-marketing-terms">Digital Marketing Terms & Conditions</a> </p></li>
							<li><p><a href="https://www.sapnagroup.com/amazon-terms">Amazon terms & conditions</a></p></li>
						</ol>
					</Grid>
				</Grow>

			</Grid>
		</div>
	)
}

export default Tnc
