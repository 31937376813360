import React, { useState, useEffect } from 'react';


import NewsGrid from '../components/NewsGrid';

import {saperator_index,display_contactBubble,seo_tags,conn_error,scroll_top} from '../js/helper.js'

function News(props) {
	seo_tags({
      title: 'News'
    });
	//var con_error = 'testtttt';
	const [items, setNewsdata] = useState(0);
	const [saperatorData, setSaperatorData] = useState(0);

	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {

		scroll_top();
		
		fetch(process.env.REACT_APP_API_DOMAIN+"news")
		.then(res => res.json())
		.then(
			(result) => {
				display_contactBubble();
				var saperatorData = [];
				for (var index = 0; index < result.length; index++) {
					saperatorData[index] = saperator_index();
				}

				setSaperatorData(saperatorData)

				setNewsdata(result)
			},
			(error) => {
				setErrorMessage(conn_error());
			}	
		)
	},[]);

	return (
		<React.Fragment>
			{ errorMessage.length ? <div className="error_message">{errorMessage}</div> : null }
			{ items.length ? <NewsGrid newsData={items} saperatorData={saperatorData} /> : null }
		</React.Fragment>
	)
}

export default News
