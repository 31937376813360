export function saperator_index(){
    return (Math.floor(Math.random() * 23)+1)
}

export function display_contactBubble(){
    // let footer_sectionObj = document.getElementsByClassName('footer_section')[0];
    // footer_sectionObj.style.display = 'none';
    // setTimeout(function(){ 
    //     let footer_sectionObj = document.getElementsByClassName('footer_section')[0];
    //     footer_sectionObj.style.display = 'block';
    // }, 5000);
}

export function scroll_top(){
    window.scrollTo({top: 0, behavior: 'smooth'});
}

export function remove_activeLink(){
    var navi_links = document.getElementsByClassName("navi_link"); 

    for (var i = 0; i < navi_links.length; i++) {
        navi_links[i].classList.remove('active');
    }
}

export function seo_tags(data = {}) {
    var seo_title = "sapnagroup - Remote teams in India since 2002";
    if(data.title){
        seo_title = data.title+" | "+seo_title;
    }

    document.title = seo_title;
}

export function conn_error(){
    return 'There seems to be a temporary network issue, Please try again after sometime.';
}