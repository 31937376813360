import React, { useState, useEffect } from 'react';
import TeamsGrid from '../components/TeamsGrid';

import {display_contactBubble,seo_tags,conn_error,scroll_top} from '../js/helper.js'

function Teams() {
	seo_tags({
      title: 'Teams'
    });

	const [teamsData, setTeamsdata] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');

  	useEffect(() => {
		scroll_top();
		fetch(process.env.REACT_APP_API_DOMAIN+"team")
		.then(res => res.json())
		.then(
			(result) => {
				display_contactBubble();
				setTeamsdata(result)
			},
			(error) => {
				setErrorMessage(conn_error());
			}	
		)
	},[]);

	return (
			<div>
				{ errorMessage.length ? <div className="error_message">{errorMessage}</div> : null }
				{ teamsData.length ? <TeamsGrid teamsData={teamsData} /> : null }
			</div>
		)	
}

export default Teams
