import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Grow from '@material-ui/core/Grow';
import {seo_tags,scroll_top} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
	jonny_title: {
		display:'inline-block',
		textAlign:'left',
		position: 'relative',
		top: '-11px',
		paddingLeft: '10px'
	},
	page_intro:{
		height:'80px',
		padding:'75px 0 15px 0'
	},
	jonny_image:{
		height:'100px',
		width:'auto',
		display:'inline-block',
	},
	page_title:{
		margin:'50px auto',
		float:'left'
	},
	page_sections_left:{
		float:'left',
		textAlign:'right'
	},
	page_sections_right:{
		float:'left',
		textAlign:'left'
	},
	headertwo:{
		margin:'2px 0'
	},
	jonny_container:{
		display:'inline-block'
	},
	jonny_contactDet:{
		border:'1px solid black'
	},
	contact_row:{
		border:'1px solid red'
	},
	section_containers_left:{
		width: '100%',
		textAlign:'right'
	},
	section_containers_right:{
		width: '100%',
		textAlign:'left',
		paddingLeft:'15px'
	},
	spacer:{
		width:'100%',
		height:'20px'
	},
	spacer_double:{
		width:'100%',
		height:'40px'
	},
	contact_det:{
		height:'20px'
		
	},
	contact_det_addressUK:{
		height:'115px'
	},
	contact_det_addressDE:{
		height:'90px'
	},
	contact_det_addressIND:{
		height:'100px'
	},
	saperator_images:{
		outline:'1px solid black'
	},

	contact_icon:{
		width:'32px',
		float:'right',
		paddingLeft:'10px',
		marginTop: '-7px'
	},
	contact_iconxing:{
		position:'relative',
		top:'5px',
		paddingLeft:'10px',
		width:'32px',
		float:'right',
		marginTop: '-7px'
	},
	contact_detspacing: {
		position:'relative',
	},
	contact_iconafacetime:{
		width:'32px',
		display:'inline-block',
		position:'relative',
		top:'3px',
		paddingLeft:'10px',
		marginTop: '-7px'
	},
	sidefloat: {
		float:'left',
		paddingBottom:'75px'
	},
	paddingTest:{
		height:'7px'
	},

	
}));

function Jonny() {
	seo_tags({
      title: 'Jonny'
    });

	const classes = useStyles();
	useEffect(() => {
		scroll_top();
	},[]);

	const mailToJonny = () =>{
		let emailto = String.fromCharCode(106,111,110,110,121,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109);
		window.location = 'mailto:'+ emailto +'?';
	   
	}
	const mailToJonnyBiz = () =>{
		let emailto = String.fromCharCode(106,111,110,110,121,64,104,117,101,98,110,101,114,46,98,122);
		window.location = 'mailto:'+ emailto +'?';
	   
	}

	return (
		<React.Fragment>
			<Grid className="maxWrapper jonnyPage" container px={5}>
				<Grow in={true}>
					<Grid item xs={12}>
						<Grid item className={classes.page_sections_left} container xs={5}>
							<div className={classes.section_containers_left}>
								<div className={classes.page_intro}>
									<CardMedia
										component="img"
										alt="Jonny"
										image="../jonny.png"
										title="Jonny"
										className={classes.jonny_image}
									/>
								</div>
								
								<div className={classes.spacer}></div>
								{/* <div className={classes.contact_det}>work UK</div> */}
								<div className={classes.spacer}>&nbsp;</div>
								{/* <div className={classes.contact_det}>work / mobile DE</div> */}
								<div className={classes.contact_det}>mobile / WhatsApp</div>
								<div className={classes.spacer_double}>&nbsp;</div>
								{/* <CardMedia
									component="img"
									alt="Sapeartor image"
									image={"../divider-images/divider21.png"}
									title="Sapeartor image"
									className={`${classes.saperator_images} seperatorImg`}
								/> */}
								{/* <div className="centerPrticeBorder">
									<img class="MuiCardMedia-root jss110 seperatorImg MuiCardMedia-mediaMarginTop MuiCardMedia-img" src="../divider-images/divider7.png" alt="Sapeartor image" title="3"/>
								</div> */}
								
								<div className={classes.contact_det}>call</div> 
								
								{/* <div className="centerPrticeBorder">
									<img class="MuiCardMedia-root jss110 seperatorImg MuiCardMedia-mediaMargin MuiCardMedia-img" src="../divider-images/divider7.png" alt="Sapeartor image" title="3"/>
								</div> */}
								<div className={classes.spacer_double}>&nbsp;</div>
								
								<div className={classes.contact_det}>work</div>

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det}>home</div>

								{/* <div className="centerPrticeBorder">
									<img class="MuiCardMedia-root jss110 seperatorImg MuiCardMedia-mediaMargin MuiCardMedia-img" src="../divider-images/divider7.png" alt="Sapeartor image" title="3"/>
								</div> */}

								<div className={classes.spacer_double}>&nbsp;</div>
								<div className={classes.contact_det}>website</div>
								{/* <div className="centerPrticeBorder">
									<img class="MuiCardMedia-root jss110 seperatorImg MuiCardMedia-mediaMargin MuiCardMedia-img" src="../divider-images/divider7.png" alt="Sapeartor image" title="3"/>
								</div> */}

								<div className={classes.spacer_double}>&nbsp;</div>
								<div className={classes.contact_det}>Skype</div>


								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det}>LinkedIn</div>


								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det}>Xing</div>
								<div className={classes.paddingTest}></div>
								{/* <div className="centerPrticeBorder">
									<img class="MuiCardMedia-root jss110 seperatorImg MuiCardMedia-mediaMargin MuiCardMedia-img" src="../divider-images/divider7.png" alt="Sapeartor image" title="3"/>
								</div> */}
								<div className={classes.spacer_double}>&nbsp;</div>
								<div className={classes.contact_det_addressUK}>work UK</div>

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det_addressDE}>work DE</div>

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det_addressIND}>work India</div>
								{/* <div className="centerPrticeBorder">
									<img class="MuiCardMedia-root jss110 seperatorImg MuiCardMedia-mediaMargin MuiCardMedia-img" src="../divider-images/divider7.png" alt="Sapeartor image" title="3"/>
								</div> */}
								<div className={classes.spacer_double}>&nbsp;</div>
								
							</div>
						</Grid>
						
						<Grid item className={classes.page_sections_right} container xs={7}>
							<div className={classes.section_containers_right}>
								<div className={classes.page_intro}>
									<CardMedia
										component="img"
										alt="Academy title"
										image="../page-headline/jonny.png"
										title="Academy title"
										className="page_header_image_jonny"
									/>
									<span>sapnagroup Ltd.</span>
								</div>
								
								<div className={classes.spacer}></div>
								{/* <div className={classes.contact_det}>
									<a href="tel:+44 1737 887808" className="contac_link">
										+44 1737 887808
										<CardMedia
											component="img"
											alt="vcf"
											image="../jonny-page/call_icon.png"
											title="vcf"
											className={classes.contact_icon}
										/>
									</a>
								</div> */}

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det}>
									<a href="tel:+49 151 14994404" className="contac_link">+49 151 14994404
										<CardMedia
											component="img"
											alt="vcf"
											image="../jonny-page/call_icon.png"
											title="vcf"
											className={classes.contact_icon}
										/>
									</a>
								</div>

								<div className={classes.spacer_double}>&nbsp;</div>
								
								<div className={classes.contact_det}>
									<a href="facetime://tschonni@me.com" className="contac_link">
										Facetime 
										<CardMedia
											component="img"
											alt="vcf"
											image="../jonny-page/video.png"
											title="vcf"
											className={classes.contact_iconafacetime}
										/>
									</a>
								</div>
								<div className={classes.spacer_double}>&nbsp;</div>
								
								<div className={classes.contact_det}>
									<span onClick={() => mailToJonny()} style={{textDecoration:'underline', cursor:'pointer'}}>jonny@sapnagroup.com</span>
								</div>

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det}>
									<span onClick={() => mailToJonnyBiz()} style={{textDecoration:'underline', cursor:'pointer'}} >jonny@huebner.bz</span>
								</div>

								<div className={classes.spacer_double}>&nbsp;</div>
								<div className={classes.contact_det}>
									<a href="https://www.sapnagroup.com/" target="_blank" rel="noopener noreferrer">www.sapnagroup.com</a>
								</div>

								<div className={classes.spacer_double}>&nbsp;</div>
								<div className={classes.contact_det}>
									<a href="skype:jonnyhubner?call" className="contac_link" rel="noopener noreferrer">
										jonnyhubner
										<CardMedia
											component="img"
											alt="vcf"
											image="../jonny-page/skype.png"
											title="vcf"
											className={classes.contact_icon}
										/>
									</a>
								</div>

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det}>
									<a href="https://www.linkedin.com/in/jonnyhubner/" target="_blank" className="contac_link" rel="noopener noreferrer">jonnyhubner
									
										<CardMedia
											component="img"
											alt="vcf"
											image="../jonny-page/inst.png"
											title="vcf"
											className={classes.contact_icon}
										/>
									</a>
								</div>

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_detspacing}>
									<a href="https://www.xing.com/profile/Jonny_Huebner2" target="_blank" className="contac_link" rel="noopener noreferrer">Jonny_Huebner2
									<CardMedia 
											component="img"
											alt="vcf" 
											image="../jonny-page/k_icon.png" 
											title="vcf"
											className={classes.contact_iconxing}
										/></a>
								</div>

								<div className={classes.spacer_double}>&nbsp;</div>
								<div className={classes.contact_det_addressUK}>
									The Old Wheel House<br/>31/37 Church Street<br/>Reigate, RH2 0AD<br/>UK
								</div>

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det_addressDE}>
									Robert-Bosch-Str. 1<br/>96050 Bamberg<br/>Germany
								</div>

								<div className={classes.spacer}>&nbsp;</div>
								<div className={classes.contact_det_addressIND}>
									307 & 308 Kamat Grand,<br/>Behind Caculo Mall,<br/>St-Inez, Panaji,<br/>Goa, India 403001<br/>
								</div>

								<div className={classes.spacer_double}>&nbsp;</div>
								
							</div>
						</Grid>
						
					</Grid>
				</Grow>
			</Grid>
			<div className="contac_bottomDownd">
							<div className={classes.sidefloat}>Download as VCF card &nbsp;</div>
							<div className={classes.sidefloat}>
									<a href="/Jonny-Hubner-v3.vcf" className="contac_link">
										<CardMedia
											component="img"
											alt="vcf"
											image="../jonny-page/downloading.png"
											title="vcf"
											className={classes.contact_icon}
										/>
									</a>
							</div>
						</div>
		</React.Fragment>
	)
}

export default Jonny
