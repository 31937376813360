import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import HttpsRedirect from 'react-https-redirect';
import {createMuiTheme,ThemeProvider} from "@material-ui/core/styles"
import ReactGA from 'react-ga';

if(process.env.REACT_APP_GOOGLE_ANALYTICS){
  console.log("analytics has been set");
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS, { standardImplementation: true });
  ReactGA.pageview(window.location.pathname + window.location.search);
}


//console.log(process.env.REACT_APP_GOOGLE_ANALYTICS);
const theme = createMuiTheme({
 breakpoints: {
   values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
}
});


ReactDOM.render(

  /*<React.StrictMode>*/
    <HttpsRedirect>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>  
    </HttpsRedirect>
  /*</React.StrictMode>*/
  ,
  document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
