import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import {scroll_top,seo_tags} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '22px;',
    fontFamily:'Rajdhani',
    color: '#000',
	padding:'20px 0'
  },
  container_div: {
    padding:'0 55px',
    border:'0px solid black'
  },
  padded_para_left: {
    paddingBottom:'30px',
	textAlign:'left'
  },
}));

function DigitalMarkettingTerms() {
    seo_tags({
      title: 'Digital Marketing Terms & Conditions'
    });

	const classes = useStyles();

	useEffect(() => {
		scroll_top();
	},[]);

	return (
		<div className ="maxWrapper">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
						<div className='heading'>
								<h1>Digital Marketing Terms & Conditions</h1>
							</div>
					</Grid>
				</Grow>

				

				<Grow in={true}>
					<Grid className={classes.padded_para_left} item xs={12}>
						<ol>
							<li><p><a href="#digital-marketing-terms">Digital marketing terms</a> </p></li>
							<li><p><a href="#payment-refund-terms">Payment / Refund terms</a> </p></li>
							<li><p><a href="#intellectual-property">Intellectual Property</a> </p></li>
							<li><p><a href="#confidentiality">Confidentiality</a> </p></li>
							<li><p><a href="#disputes">Disputes, Liability, and Indemnity</a> </p></li>
							<li><p><a href="#governing-law">Governing Law</a> </p></li>
            
						</ol>
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid id="server" className={classes.page_title} item xs={12}>
				<ol>
					<span href="#" name="server">&nbsp;</span>
                    <li>
                        <h4 id="digital-marketing-terms">Digital marketing terms</h4>
                         <ul>
                            <li>
                                <p><span id="sshtop">&nbsp;</span>During initial brief to the sapnagroup digital marketing team briefing, it is client’s responsibility to provide us with all the information required for the team to carry out all tasks to recommend the appropriate marketing strategy.</p>
                            </li>
                            <li>
                                <p><span id="ftpestop">&nbsp;</span>All proposals remain valid for THIRTY (30) days from the date of issue by sapnagroup to the client unless otherwise agreed in writing by the sapnagroup digital marketing team. An order or instruction to commence development from the client shall not be considered a contract between the client and sapnagroup digital marketing team unless accepted by sapnagroup whereupon these Terms and Conditions will be deemed to have been accepted unconditionally by the client. </p>
                            </li>
                            <li>
                                <p><span id="bkptop">&nbsp;</span>Once the service proposal has been finalised, any additions, changes or enhancements in services to be provided will affect the proposal and may incur additional cost and a revised delivery date. All additional work, over and above the estimates is charged separately. Under no circumstances will sapnagroup be liable for any delays caused by revisions in the digital marketing brief. </p>
                            </li>
                            <li>
                                <p><span id="phptop">&nbsp;</span>The digital marketing team will make changes to the website content only after approval from the client. </p>
                            </li>
                            <li>
                                <p><span id="mirrortop">&nbsp;</span>The client should provide the required access to its Google Analytics, Google Search Console, AdWords account and the CMS to enable the digital marketing team to make changes as required to implement the recommendations made to the client. </p>
                            </li>
                            <li>
                                <p><span id="mysqltop">&nbsp;</span>Client understands that the contact details of the Clients and all parties (contractors, suppliers etc) involved and the communications may be shared outside the EEA as well with our staff members and suppliers etc, only for the purpose of executing our service. The sharing of these details will enable understanding the requirements and help communicate with all the relevant parties. This will also include sensitive access details like passwords to databases, FTP accounts, google analytics etc as and where relevant. </p>
                            </li>
                        </ul>
                        <br /><span id="login"></span>
                    </li>
                        
					<li>
                        <h4 id="payment-refund-terms">Payment / Refund terms</h4>
                        <ul>
                            <li>
                                <p><span id="mysqltop"></span>All prices are quoted in GBP or EUR exclusive of VAT unless specified.</p>
                            </li>
                            <li>
                                <p><span id="mysqltop"></span>Invoices are issued based on project progress at the end of each month. Payments can be made via cheque or bank transfer. Payment terms are 30 days after receipt of the invoice via email.</p>
                            </li>
                            <li>
                               <p><span id="mysqltop"></span>All payments to external parties such as Google Inc, Facebook Inc or any other third party on which sapnagroup digital division runs advertising campaigns on behalf of the client are to be paid by the client directly. sapnagroup is not responsible or liable to pay for these spends.</p>
                            </li>
                            
                        </ul>
                        <br /><span id="exchange">&nbsp;</span>
                    </li>
					
					  <li>
                        <h4 id="intellectual-property">Intellectual Property</h4>
                        <ul>
                            <li>
                                <p><span id="mysqltop"></span>Various methods used by sapnagroup digital marketing team are developed by the team. The various recommendations made by the team should not be used for any other website other than the website the recommendations are made for as agreed in the contract with the client.</p>
                            </li>
                            <li>
                                <p><span id="mysqltop"></span>sapnagroup digital marketing team cannot take responsibility for any copyright infringements caused by materials submitted by the client or added by the client to the website via the CMS (Content Management System). We reserve the right to refuse any material of a copyrighted nature unless adequate proof is given of permission to use such material.</p>
                            </li>
                        </ul>
                        <br /><span id="scripting"></span>
                    </li>
					
					<li>
                        <h4 id="confidentiality">Confidentiality</h4>
                       <ul>
							<li>
								<p><span id="mysqltop"></span>Unless notified in writing by the client any information or documentation supplied by the client to sapnagroup shall not be deemed as confidential.</p>
							</li>
						</ul>
                        <span id="backup">&nbsp;</span><br />
                    </li>
					
					<li>
						<h4 id="disputes">Disputes, Liability, and Indemnity</h4>
                        <ul>
                            <li>
                                <p><span id="mysqltop">&nbsp;</span>In the event of a dispute, sapnagroup reserves the right to charge the client in full for the work done as well as for the resources spent in managing the dispute. In such an event, sapnagroup reserves the right to charge the client without honouring any discounts that were previously honoured in good faith. Under no circumstances will sapnagroup be liable for any damages arising from misrepresentation or misinformation.</p>
                            </li>

                            <li>
                                <p><span id="mysqltop">&nbsp;</span>sapnagroup digital team reserves the right to refuse service to any client if these are not aligned with our business operating principles and policies.</p>
                     
                            </li>

                            <li>
                                <p><span id="mysqltop">&nbsp;</span>sapnagroup digital team provides its services as is, without any guarantees on ranking or changes in the traffic to the website. The team is not responsible for any change in the ranking or specific key phrases or traffic to the website which is due to reasons beyond the control of the sapnagroup digital marketing team.</p>
                     
                            </li>

                            <li>
                                <p><span id="mysqltop">&nbsp;</span>All our recommendations & services are in line with Google’s webmaster’s guidelines given <a href="https://support.google.com/webmasters/topic/6001981?hl=en&ref_topic=3309300">here</a>.</p>
                     
                            </li>

                            <li>
								<p><span id="mysqltop">&nbsp;</span>sapnagroup digital team is not responsible for any costs incurred by the client towards AdWords spends which are a result of changes not made by the sapnagroup digital team members.</p>
							</li> 

                            <li>
								<p><span id="mysqltop">&nbsp;</span>sapnagroup digital team is not responsible for any loss of any historical tracking data which is caused due to changes made by any other third party to the Google Analytics account.</p>
							</li>

                            <li>
								<p><span id="mysqltop">&nbsp;</span>In performing off-page optimisation the sapnagroup digital team will make every effort to link only to legitimate and reputable websites. sapnagroup digital team is not responsible for any back links to the client’s website which are not created by the digital team.</p>
							</li>
							<li>
								<p><span id="mysqltop">&nbsp;</span>Client will indemnify and hold sapnagroup digital team, employees, agents, officers, directors, contractors and sub-contractors (the “Indemnified Parties”) harmless from your breach of any of these Terms And Conditions or any other terms, conditions, policies or procedures herein, including, without limitation, any use of content other than as expressly authorised in these Terms and Conditions. Client agrees that the indemnified parties will have no liability in connection with any such breach or unauthorised use, and you agree to indemnify and hold harmless the Indemnified Parties from any and all resulting loss, damages, judgments, awards, costs, expenses, and attorneys’ fees in connection therewith. You will also indemnify and hold the Indemnified Parties harmless from and against any claims brought by third parties arising out of your use of the information obtained from sapnagroup.</p>
							</li>
							
							<li>
								<p><span id="mysqltop">&nbsp;</span>sapnagroup digital team will not be liable for any breach of the agreement which is caused by a matter beyond its reasonable control including but not limited to Act of God, fire, lightning, explosion, war, disorder, flood, earth quake, industrial disputes (whether or not involving their employees), extremely severe weather, or acts of local or central government or other competent authorities.</p>
							</li>
                        </ul>
                    </li>
					<li>
                        <h4 id="governing-law">Governing Law</h4>
                        <ul>
                            <li>
                                <p><span id="mysqltop">&nbsp;</span>Any contract incorporating these Terms and Conditions shall be governed by and construed in accordance with the laws of England and both parties hereby agree to submit to the jurisdiction of the English courts.</p>
                            </li>
                            <li>
                                <p>Please note that sapnagroup reserves the right to change the terms and conditions without notice.</p>
                            </li>
                        </ul>
                        <br /><span id="website">&nbsp;</span>
                    </li>	
				
                </ol>
					<br/><br/><br/>
					<div><p>Version 1.0 - Last updated 11/09/2017</p></div>
					</Grid>
				</Grow>

				
				

		
			</Grid>
		</div>
	)
}

export default DigitalMarkettingTerms
