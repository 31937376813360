import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import CardMedia from '@material-ui/core/CardMedia';

import {scroll_top,seo_tags} from '../js/helper.js'

const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px',
	textAlign:'left'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '22px;',
    fontFamily:'Rajdhani',
    color: '#000',
	padding:'20px 0'
  },
  container_div: {
    padding:'0 55px',
    border:'0px solid black'
  },
  bold_heading: {
    wordWrap:'break-word',
  },
}));

function Impressium() {
	seo_tags({
      title: 'Impressum'
    });
	const classes = useStyles();

	useEffect(() => {
		scroll_top();
	},[]);

	const mailToHello = () =>{
		let emailto = String.fromCharCode(104,101,108,108,111,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109);
		window.location = 'mailto:'+ emailto +'?';
	   
	}
	
	return (
		<div className ="maxWrapper">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
						<CardMedia
							component="img"
							alt="Impressum"
							image="../page-headline/impressium.png"
							title="Impressum"
							className="page_header_image"
						/>
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
						{/* <b>Angaben gemäß § 5 TMG</b><br /> */}
						sapnagroup Ltd.<br />
						The Old Wheel House<br />
						31/37 Church Street<br />
						Reigate, Surrey<br />
						RH2 0AD<br />
						Vereinigtes Königreich <br /><br />

						Vertretungsberechtigter Geschäftsführer: Claus (Jonny) Hübner<br />
						Registrierte Büroadresse: The Old Wheel House | 31/37 Church Street |
						Reigate | Surrey | RH2 0AD | Großbritannien<br />
						Registernummer: 4533465 (in England registriert)<br />
						Umsatzsteuer-Identifikationsnummer: GB 802 7568 29<br /><br />

						<b>Kontakt</b><br />
						Telefon: +44 1737 887808<br />
						E-Mail: <span onClick={() => mailToHello()} style={{textDecoration:'underline', cursor:'pointer'}} >hello@sapnagroup.com</span><br /><br />

						<b>Redaktionell Verantwortlicher</b><br />
						Claus (Jonny) Hübner<br /><br />

						<b>EU-Streitschlichtung</b><br />
						Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="blank">https://ec.europa.eu/consumers/odr</a><br />
						Unsere E-Mail-Adresse finden Sie oben im Impressum.<br/><br />


						<b>Verbraucherstreitbeilegung / Universalschlichtungsstelle</b><br />	
						Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
einer Verbraucherschlichtungsstelle teilzunehmen.<br /><br />

						{/* <b>Haftung für Inhalte</b><br />
						Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
übermittelte oder gespeicherte fremde Informationen zu überwachen oder
nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
hinweisen.<br /> 
						Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br/><br />*/}

<b>Haftung für Links</b><br />
						Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br /><br/>
Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
umgehend entfernen.<br /><br />


<b>Urheberrecht</b><br />
						Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br /><br />

Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br /><br />
						
						
						
					</Grid>
				</Grow>
			</Grid>
		</div>
	)
}

export default Impressium
