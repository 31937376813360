import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
//import slideData from "../data/brochure/all.json";
import { useParams } from 'react-router-dom';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

// import "./styles.css";

// import required modules
import { FreeMode,  Thumbs, Navigation } from "swiper";


function BrochureSlider() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    //const location = useLocation();
    //const currentPath = location.pathname;
    const [slides, setSlides] = useState([]);
    let slideTypeValue = 'imageRight';
    const { id } = useParams();
    const [jsonData, setJsonData] = useState(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        fetch(`/brochure/${id}.json`)
        .then((response) => response.json())
        .then((data) => {
            setJsonData(data);            
          })
        .catch((error) => console.error(error));

        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(0, 0); // 0 is the slide index, and 0 is the transition duration in milliseconds
          }
    }, [id]);

    if (!jsonData) {
    return <div>Loading...</div>;
    }

  return (
    <>
    <div className="brochureSliderwraper">
        <Swiper
            ref={swiperRef}
            observeSlideChildren={true}
            style={{
                "--swiper-navigation-color": "#000",
                "--swiper-pagination-color": "#000",
            }}
    
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"             
        >
            {jsonData.map((slide) => (
                <SwiperSlide  key={slide.id}>
                    {/* { slide.slidetype === slideTypeValue ? (
                    <Grid container>
                    
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} md={slide.contentcol} align={slide.contentalign}>
                        {slide.iconContent !== "" ? (
                            <div className='smallImgWraper'>                                                  
                                <h2 dangerouslySetInnerHTML={{ __html: slide.heading }} /> 
                                {slide.iconContent.map((icondata, index) => (
                                    <div className='iconsTextBlock' key={index}>
                                <div className="icons"><img src={icondata.iconImg} alt="" title="" /></div>                                            
                                <p>
                                    {icondata.iconDesc}
                                </p>
                                    </div>  
                                ))}    
                                <h3 align={'center'}>{slide.subheading} </h3>  
                            </div>
                            
                                    ) : (
                            <div >
                            <h2 dangerouslySetInnerHTML={{ __html: slide.heading }} /> 
                            <p dangerouslySetInnerHTML={{ __html: slide.description }} />
                                    
                            </div>         
                        )}
                            
                        </Grid>

                        {slide.iconContent !== "" ? ( ""
                        ):(
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        )}
                        <Grid item xs={12} md={slide.imgCol} align={slide.imageAlignalign}>
                            <CardMedia
                                component="img"
                                alt={slide.imgName}
                                image={slide.url}
                                title={slide.imgName}
                                className="CoverPic"
                            />
                        </Grid>
                            <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                    ) : ( */}
                            
                    <Grid container className={ slide.slidetype === slideTypeValue ? 'swiper-colmn-reverse' : ''}>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} md={slide.imgCol} align={slide.imageAlignalign}>
                            <CardMedia
                                component="img"
                                alt={slide.imgName}
                                image={slide.url}
                                title={slide.imgName}
                                className="CoverPic"
                            />
                        </Grid>
                        {slide.iconContent !== "" ? ( ""
                        ):(
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        )}              
                                    
                        <Grid item xs={12} md={slide.contentcol} align={slide.contentalign}>
                        {slide.iconContent !== "" ? (
                            <div className='smallImgWraper'>                                                  
                                    <h2 dangerouslySetInnerHTML={{ __html: slide.heading }} /> 
                                    {slide.iconContent.map((icondata, index) => (
                                <div className='iconsTextBlock' key={index}>
                                    <div className="icons"><img src={icondata.iconImg} alt="" title="" /></div>                                            
                                    <p>
                                        {icondata.iconDesc}
                                    </p>
                                        </div>  
                                    ))}  
                                    <h3 align={'center'}>{slide.subheading}</h3>    
                            </div>
                            
                                    ) : (
                            <div >
                            <h2 dangerouslySetInnerHTML={{ __html: slide.heading }} />  
                            <p dangerouslySetInnerHTML={{ __html: slide.description }} />  
                            </div>          
                            )}
                            
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                    {/* )} */}
                        
                    </SwiperSlide>     
            ))}
                  
           {/* <SwiperSlide className={selectedTab === '' ? '' : 'hide '}>
                 <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} sm={12} md={7} align={'left'}>
                        <h2>Director & CO-Founder</h2>
                        <p>
                            My name is Nilesh Nayak and I founded sapnagroup in 2002 together with Anurag Jain and Jonny Hübner. In it's 20th year the company is still owned and run by the 3 original founders. Our development offices are based in Goa, India. We currently have a team of over 60 spread across 3 offices.<br/><br/>
                            We are a very flat organisation with minimal hierarchy which allows us to cut the bureaucratic red tape. Our attrition rates are much lower than industry standards. We ensure that our team members are in touch with the latest trends in technology through our on-going in house training programs.<br/><br/>
                            Even though we can offer reasonable rates, we try not to compete with low price competitors. We focus on quality of work with a very professional approach. Our aim is to establish a long term relationship with our clients.<br/><br/>
                            As majority of our clients are based in the UK, we are set up as a Private Limited Company in the Uk
                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} sm={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Nilesh Nayak"
                            image='../brochure-img/Nilesh.png'
                            title="Nilesh Nayak"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>          
           <SwiperSlide  className={`about ${(selectedTab === 'about' || selectedTab === '' ) ? '' : 'hide'}`} >
              <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'right'}>
                       <h2>Director & CO-Founder</h2>
                       <p>
                            My name is Jonny Hübner and I'm looking forward to commuting again between Bamberg in Germany and London in the UK. I hope you'll find this brochure interesting and it will give you a good idea of the services we can offer.
                           <br/>
                           <br/>
                           We strongly believe in long term relationships - with our clients, team, suppliers and partners. Our goal has always been to create a sustainable operation and we have managed to service and grow with our first clients for nearly two decades now. If we manage to awaken your interest, I would love to meet up in a video call or -if possible with you in person and discuss what we can do for you. 
                           <br/><br/>
                           Please contact me on E-Mail (jonny@sapngroup.com), Skype (jonnyhubner) or just call me on +44 1737 887808 or +49 151 14994404.
                           <br/><br/>
                           looking forward to speaking to you soon !
                       </p>
                    </Grid>
                   <Grid item xs={12} md={1} align={'left'}></Grid>
                   

                    <Grid item xs={12} md={2}  align={'left'}>
                       <CardMedia
                           component="img"
                           alt="Jonny Hübner"
                           image='../brochure-img/Jonny.png'
                           title="Jonny Hübner"
                           className="CoverPic"
                       />
                   </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
               </Grid>
          </SwiperSlide>          
         <SwiperSlide  className={`about" ${(selectedTab === 'about' || selectedTab === '') ? '' : 'hide'}`}  >
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="My Family Care"
                            image='../brochure-img/MFC.png'
                            title="My Family Care"
                            className="CoverPic"
                        />
                        
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>My Family Care</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/robust-system.png' alt="" title="" /></div>
                                <p>Robust system which
                                    handles over 500,000 hours 
                                    of emergency childcare 
                                    bookings per month
                                </p>
                            </div>
                           c
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/clients.png' alt="" title="" /></div>
                                <p>Key clients using this 
                                    system include global 
                                    businesses like Bank of 
                                    America Merrill Lynch,
                                    Procter & Gamble, KMPG
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2002</h3>
                        </div>
                        
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>             
            <SwiperSlide className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Learnify LMS"
                            image='../brochure-img/Learnify-LMS.png'
                            title="Learnify LMS"
                            className="CoverPic"
                        />
                        
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Learnify LMS</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/saas.png' alt="" title="" /></div>
                                <p>SAAS product developed 
                                    for our client Code 
                                    Interactive
                                </p>
                            </div>
                        <div className='iconsTextBlock'>
                            <div className="icons"><img src='../brochure-img/include.png' alt="" title="" /></div>
                            <p>Includes a sophisticated 
                                    content creator which is 
                                    modelled around Microsoft 
                                    Powerpoint; offers an array 
                                    of features to create 
                                    complex slides
                            </p>
                        </div>
                        <div className='iconsTextBlock'>
                            <div className="icons"><img src='../brochure-img/sething.png' alt="" title="" /></div>
                            <p>A Learning Management 
                                    System with powerful 
                                    statistics for the trainers
                            </p>
                        </div>
                        <h3 align={'center'}>Client since 2009</h3>
                    </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>CTO & C0-Founder</h2>
                        <p>
                            My name is Anurag Jain. I am sapnagroup’s CTO - but to 
                            behonest, I rarely quote my designation.<br/><br/>
                            We develop all our web applications on a LAMP platform
                            (Linux, Apache, MySQL and PHP). We have more than 15
                            years of experience in this area. Our core competency 
                            lies in producing custom applications using raw PHP 
                            knowledge. Over the years we have build our own 
                            customised frameworks. We also code out native and 
                            hybrid apps for iOS and Android.<br/><br/>
                            For the front-end, we have a team of developers 
                            conversant with technologies like React JS, AngularJS, 
                            Vue JS, Node JS, HTML5 and CSS3. We ensure that the 
                            code not only works but is efficient as well as secure. We 
                            conduct penetration tests on our own code to check for 
                            any vulnerabilities. Our knowledge on internet security 
                            helps follow correct security procedures when coding.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Anurag Jain"
                            image='../brochure-img/Anurag.png'
                            title="Anurag Jain"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Pallavi Borkar"
                            image='../brochure-img/pallavi.png'
                            title="Pallavi Borkar"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>Lead Developer</h2>
                        <p>
                        My name is Pallavi Borkar and I’m the lead developer for 
                        our biggest client My Family Care (MFC). I head a team of 
                        12 dedicated developers who constantly work on 
                        improving and updating the existing MFC websites and 
                        apps. In addition to this we add new modules to the 
                        existing software as well as develop new applications for 
                        web and mobile.<br/><br/>
                        For the last five years we have moved to Agile method 
                        of development. We use JIRA to manage the workflow, 
                        Confluence for documentation and Slack for everyday 
                        communication.<br/><br/>
                        We work directly with two UK based MFC project 
                        managers as well as other MFC team members based at 
                        the MFC office in London.<br/><br/>
                        In August 2020, our systems handled bookings for over 
                        500,000 hours of childcare for the first time with virtually 
                        zero disruption. We are very proud of that!
                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`about" ${(selectedTab === 'about' || selectedTab ==='') ? '' : 'hide'}`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Learnify LMS"
                            image='../brochure-img/the-lakes.png'
                            title="The Lakes"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>The Lakes</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/3d-ico.png' alt="3d" title="3d" /></div>
                                <p>3D interactive model 
                                    developed using Three.js 
                                    allowing users to rotate, 
                                    pan and click parts of the 
                                    3D model
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/showpoints.png' alt="360 view" title="360 view" /></div>
                                <p>Shows floor plans, 360 
                                    view, gallery when clicked 
                                    on objects
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Optimised.png' alt="Optimised" title="Optimised" /></div>
                                <p>Optimised loading and 
                                    works across latest 
                                    browsers and platforms 
                                    including mobile devices
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2020</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
             <SwiperSlide className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="PCCW"
                            image='../brochure-img/pccw.png'
                            title="PCCW"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>PCCW</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Global-website.png' alt="Global website" title="Global website" /></div>
                                <p>Global website for a 
                                    multinational 
                                    company developed 
                                    using WordPress and 
                                    plugins
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Content-Security.png' alt="Content-Security-Policy" title="Content-Security-Policy" /></div>
                                <p>Content-Security-Policy HTTP response 
                                    headers are added to 
                                    the site, which helps 
                                    to reduce XSS risks 
                                    and data injection 
                                    attacks
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Additional-password.png' alt="Additional password " title="Additional password " /></div>
                                <p>Additional password 
                                    security by using 
                                    plugins like Password 
                                    Policy Manager and 
                                    WordFence security
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2019</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Verbatim"
                            image='../brochure-img/Verbatim.png'
                            title="Verbatim"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Verbatim</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Multi-lingual.png' alt="Multi lingual" title="Multi lingual" /></div>
                                <p>Multi-lingual website 
                                    with approval based 
                                    content management 
                                    system (CMS)

                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Central-image.png' alt="Central image" title="Central image" /></div>
                                <p>Central image and 
                                        marketing materials 
                                        library
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Integration-3.png' alt="Integration" title="Integration" /></div>
                                <p>Integration with online 
                                    shop and server 
                                    management
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2002</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`wordpress" ${(selectedTab === 'wordpress' || selectedTab === '') ? '' : 'hide'}`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="WebPro"
                            image='../brochure-img/webpro.png'
                            title="WebPro"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>WebPro.legal</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/SAAS-service.png' alt="SAAS service" title="SAAS service" /></div>
                                <p>SAAS service for law 
                                    firms to create their 
                                    own websites

                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Consists-of-an-innovative.png' alt="Consists of an innovative" title="Consists of an innovative" /></div>
                                <p>Consists of an innovative 
                                        wizard which helps 
                                        lawyers create a fully 
                                        functioning website 
                                        including content in 
                                        minutes
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Automatic-subdomain.png' alt="Automatic subdomain" title="Automatic subdomain" /></div>
                                <p>Automatic subdomain 
                                    generation without 
                                    manual intervention of a 
                                    system administrator
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2013</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>        
            <SwiperSlide className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Weltkulturerbelauf"
                            image='../brochure-img/Weltkulturerbelauf.png'
                            title="Weltkulturerbelauf"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Weltkulturerbelauf</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/SAAS-service.png' alt="Website for a popular" title="Website for a popular" /></div>
                                <p>Website for a popular running 
                                    event with multiple adults’ 
                                    and kids’ runs

                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Consists-of-an-innovative.png' alt="Nearly 2,000 runner" title="Nearly 2,000 runner" /></div>
                                <p>Nearly 2,000 runner 
                                    registrations in the first 30 
                                    minutes. This includes 
                                    registering the users and 
                                    accepting online payments
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Automatic-subdomain.png' alt="System not only" title="System not only" /></div>
                                <p>System not only holds the 
                                    entire runners database but 
                                    also has a complex logic for 
                                    waiting lists and discount 
                                    vouchers
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2010</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Suck uk"
                            image='../brochure-img/suckuk.png'
                            title="Suck uk"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>SUCK UK</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Online-shop.png' alt="Online shop" title="Online shop" /></div>
                                <p>Online shop with bespoke 
                                    web design and 
                                    integration with client’s 
                                    ERP system and client’s 
                                    OMS

                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Separate-shop.png' alt="Separate shop" title="Separate shop" /></div>
                                <p>Separate shop for retail 
                                    buyers and wholesale 
                                    distributors with 
                                    differential pricing for each 
                                    set
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Automatic-subdomain.png' alt="System not only" title="System not only" /></div>
                                <p>Complex inventory 
                                    management to account 
                                    for multiple warehousing 
                                    followed by the client
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2010</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide  className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Storath Chocolatier"
                            image='../brochure-img/Storath-Chocolatier.png'
                            title="Storath Chocolatier"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Storath-Chocolatier</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Shopify.png' alt="Built on the Shopify" title="Built on the Shopify" /></div>
                                <p>Built on the Shopify 
                                    platform with 
                                    adjustments as per the 
                                    German legal 
                                    requirements

                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Selecting.png' alt="Selecting and customising" title="Selecting and customising" /></div>
                                <p>Selecting and 
                                    customising template, 
                                    importing of products 
                                    and other conten
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Integration-2.png' alt="Integration of multiple" title="Integration of multiple" /></div>
                                <p>Integration of multiple 
                                    payment systems, DHL 
                                    API and MailChimp
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2018</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Mohin Naik"
                            image='../brochure-img/Mohin.png'
                            title="Mohin Naik"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>Server Team Head</h2>
                        <p>
                            My name is Mohin Naik and I am heading our server 
                            team. We host websites and other software for our 
                            clients. We rent most of our servers from Hetzner, 
                            Germany with whom we have an on-going business 
                            relationship for over 17 years.<br/><br/>
                            We host and maintain Linux servers and this is our forte. 
                            Our prefered Linux flavour is Debian and all our servers 
                            are set up with multiple levels of security and backups. 
                            Our mirrored server for every live server is something 
                            which we hold in pride because this was our very own 
                            innovation. In case of failure of the live server, we can 
                            switch to a backup server in under an hour with virtually 
                            no data loss. For our mission critical clients for whom 
                            uptime and retention of data is invaluable, this solution 
                            eliminates a lot of their fears and headaches.<br/><br/>
                            Over the years and thanks to regular penetration tests 
                            we have increased security of our servers to a pretty 
                            high level and we take pride that we have managed to overt any major breach.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide  className={`bespoke-software-solutions" ${(selectedTab === 'bespoke-software-solutions' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>App Developer</h2>
                        <p>
                        My name is Harish Mangeshkar and I’m the lead developer 
                        for the mobile apps team. Over the years we have 
                        developed a range of different apps right from hardcore 
                        native apps to hybrid apps.<br/><br/>
                        We have created apps for iOS, Android and also 
                        Progressive Web apps. Besides familiarity with coding 
                        languages like Swift (Native IOS) and Java (Native 
                        Android), we have experience with frameworks like Ionic. 
                        The apps we have developed encompass a large variety 
                        of applications like an Uber like app for nannies in the UK, 
                        a GPS tracking app for delivery boys in Germany and a 
                        ticket booking app for a local cinema.<br/><br/>
                        Our next endeavour is to venture into areas like 
                        Augmented Reality via a mobile device.

                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Harish Mangeshkar"
                            image='../brochure-img/Harish.png'
                            title="Harish Mangeshkar"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`server-management" ${(selectedTab === 'server-management' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Storath Chocolatier"
                            image='../brochure-img/buc-app.png'
                            title="Storath Chocolatier"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>My Family Care<br/>BUC App</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/robust-system.png' alt="A convenient" title="A convenient" /></div>
                                <p>A convenient way to 
                                    book nannies and 
                                    nurseries

                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/App-is-smart.png' alt="App is smart enough" title="App is smart enough" /></div>
                                <p>App is smart enough to 
                                    calculate all subsidies 
                                    and other factors 
                                    pertaining to that parent
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/App-acts.png' alt="App acts as" title="App acts as" /></div>
                                <p>App acts as a second 
                                    medium to book besides 
                                    the website
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2002</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`apps" ${(selectedTab === 'apps' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Nanny app"
                            image='../brochure-img/Nanny-app.png'
                            title="Nanny app"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Nanny app</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Based.png' alt="Based on the Uber concept" title="Based on the Uber concept" /></div>
                                <p>Based on the Uber concept 
                                    of connecting parents to 
                                    nannies much faster than 
                                    the traditional manual 
                                    process
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/ring.png' alt="When a booking is made" title="When a booking is made" /></div>
                                <p>When a booking is made, 
                                    nannies who are within a 
                                    certain radius of the booking 
                                    address and those who 
                                    meet the required criteria 
                                    get alerts
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/first-nanny.png' alt="The first nanny" title="The first nanny" /></div>
                                <p>The first nanny who accepts 
                                    the booking is allocated the 
                                    booking
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2002</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`apps" ${(selectedTab === 'apps' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="SGN"
                            image='../brochure-img/sgn.png'
                            title="SGN"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>SGN app</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/magazine.png' alt="App for monthly magazine" title="App for monthly magazine" /></div>
                                <p>App for monthly magazine 
                                    available for phone and 
                                    tablet, iOS and Android
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/news.png' alt="News" title="News" /></div>
                                <p>Latest news feed and option 
                                    to read the latest 10 issues
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/eye.png' alt="Offline reading" title="Offline reading" /></div>
                                <p>Offline reading functionality 
                                    and subscription paywall
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2015</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`apps" ${(selectedTab === 'apps' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Lichtspiel Apple"
                            image='../brochure-img/Lichtspiel.png'
                            title="Lichtspiel Apple"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Lichtspiel Apple <br/>
                                Watch App
                            </h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Lists-movie.png' alt="Lists movie shows" title="Lists movie shows" /></div>
                                <p>Lists movie shows for 
                                    next 3 days on the 
                                    Apple Watch
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/notebook.png' alt="Detailed information" title="Detailed information" /></div>
                                <p>Detailed information for 
                                    each movie displayed
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Android-Wear-version.png' alt="Android Wear version" title="Android Wear version" /></div>
                                <p>We are working on an 
                                    Android Wear version
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2010</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`apps" ${(selectedTab === 'apps' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Prasad Gawas"
                            image='../brochure-img/prasad.png'
                            title="Prasad Gawas"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>Senior Developer</h2>
                        <p>
                            My name is Prasad Gawas and I’m a senior member of
                            the sapna security team. The system developed by us for
                            our client My Family Care underwent nearly 4-5 
                            penetration tests each year and we passed them with 
                            flying colours each time. This taught us a lot on the 
                            security front but it also set us thinking as to why we 
                            shouldn’t be on the other side testing websites and apps 
                            for vulnerabilities.<br/><br/>
                            hus was born sapna security, a company which 
                            promises to do a thorough security review of your 
                            website at costs far lower than established security 
                            companies. We offer security and GDPR reviews as well 
                            as full fledged penetration tests for websites and apps. 
                            The knowledge gained from the security team also helps 
                            our coding and server teams to build in the extra level of 
                            security for our applications and thus deliver more 
                            secure solutions for our clients.
                        </p>
                    </Grid>
                    
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`apps" ${(selectedTab === 'apps' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Ethical Hacking"
                            image='../brochure-img/Ethical-Hacking.png'
                            title="Ethical Hacking"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Ethical Hacking</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/vulnerabilities.png' alt="Using the best industry" title="Using the best industry" /></div>
                                <p>Using the best industry 
                                    standards with both 
                                    automated and manual 
                                    testing to check for 
                                    vulnerabilities
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/details-ico.png' alt="Detailed report published" title="Detailed report published" /></div>
                                <p>Detailed report published 
                                    with priority level for 
                                    each issue
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Conference.png' alt="Conference call support " title="Conference call support " /></div>
                                <p>Conference call support 
                                    to help you understand 
                                    the issues and the way 
                                    forward including a free 
                                    retest of issues
                                </p>
                            </div>
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`security" ${(selectedTab === 'security' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Security Audit"
                            image='../brochure-img/Security-Audit.png'
                            title="Security Audit"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Security Audit</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Includes-gathering.png' alt="Includes gathering" title="Includes gathering" /></div>
                                <p> 
                                    information on suppliers, 
                                    data flow, data 
                                    classification, network
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Includes-security.png' alt="Includes security" title="Includes security" /></div>
                                <p>Includes security audit 
                                    fact finding in the form 
                                    of questionnaire across 
                                    data security, 
                                    infrastructure, access 
                                    management, 
                                    monitoring/logging and 
                                    organisational policy
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Findings-published.png' alt="Findings published " title="Findings published" /></div>
                                <p>Findings published as a 
                                risk register which helps 
                                understand which issues 
                                should be focused on.
                                </p>
                            </div>
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`security" ${(selectedTab === 'security' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Stress Test"
                            image='../brochure-img/Stress-Test.png'
                            title="Stress Test"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Stress Test</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/vulnerabilities.png' alt="Test user experience" title="Test user experience" /></div>
                                <p>Test user experience 
                                    under load, isolate for 
                                    problems and 
                                    bottlenecks in the 
                                    system/application.
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/details-ico.png' alt="Ensure scalability" title="Ensure scalability" /></div>
                                <p>Ensure scalability, verify 
                                    performance and SLA 
                                    requirements
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Automatic-subdomain.png' alt="40+ browsers/devices" title="40+ browsers/devices" /></div>
                                <p>40+ browsers/devices, 
                                    Real user simulations, 
                                    Multi-step scripting.
                                </p>
                            </div>
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`security" ${(selectedTab === 'security' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>Digital Marketing <br/>Manager</h2>
                        <p>
                            My name is Loveleen Fernandes and I’m leading a team
                            of digital marketing experts, Google Adwords certified
                            professionals, Shopify, Wix and WordPress developers.
                            <br/><br/>
                            I have enjoyed managing SEO (Search Engine 
                            Optimisation) and SEM/PPC (Search Engine Marketing) 
                            campaigns for over a decade now. Other ad hoc tasks of 
                            our team include content management and ecommerce 
                            data entry work.
                            <br/><br/>
                            In the last few years, we have created a range of small
                            websites with Wix. It is relatively fast and cost effective 
                            to develop these highly professional and mobile 
                            optimised websites. We can also help to set up or 
                            manage existing WordPress installations for you.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Loveleen Fernandes"
                            image='../brochure-img/Loveleen.png'
                            title="Loveleen Fernandes"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide  className={`seo" ${(selectedTab === 'seo' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="SEO for UHS Group"
                            image='../brochure-img/SEO.png'
                            title="SEO for UHS Group"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>SEO for UHS Group</h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/seo-ico.png' alt="SEO" title="SEO" /></div>
                                <p>Planning the SEO 
                                    strategy and monthly 
                                    approach for a large 
                                    website; English section 
                                    alone contains 11 
                                    categories and 800+ 
                                    products
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/search-ico.png' alt="Keyword" title="Keyword" /></div>
                                <p>
                                    Keyword analysis and  content optimisation
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Rectifying.png' alt="Rectifying technical" title="Rectifying technical" /></div>
                                <p>Rectifying technical 
                                    issues and ensuring the 
                                    website is made SEO 
                                    friendly
                                </p>
                            </div>
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`other-services" ${(selectedTab === 'other-services' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="PPC for Emergencychildcare.co.uk"
                            image='../brochure-img/ppc.png'
                            title="PPC for Emergencychildcare.co.uk"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>PPC for <br/>
                                Emergencychildcare.co.uk
                            </h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/seo-ico.png' alt="Brand faces" title="Brand faces" /></div>
                                <p>Brand faces a lot of 
                                    competition from all 
                                    other childcare 
                                    providers / agencies in 
                                    the UK
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/search-ico.png' alt="Ever growing competition" title="Ever growing competition" /></div>
                                <p>
                                    Ever growing competition 
                                    leads to on-going 
                                    increase in average cost 
                                    per click; using various 
                                    PPC strategies we can 
                                    bid to obtain top 
                                    positions and reasonable 
                                    number of clicks
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Rectifying.png' alt="AB testing" title="AB testing" /></div>
                                <p>AB testing with landing 
                                        pages help to ensure 
                                        that we are attracting 
                                        potential visitors
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2006</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`content-creation" ${(selectedTab === 'content-creation' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>Content Creation Head</h2>
                        <p>
                            My name is Nila Nayak and I head the Content Creation 
                            team. This is a dedicated team which handles inputting 
                            of content for various clients. Our team is multi-talented 
                            with skill sets in Microsoft Office to Amazon Seller 
                            Services.
                            <br/><br/>
                            We have handled various types of assignments for 
                            multiple clients. We have expertise in inputting content 
                            for Amazon Sellers and Amazon Vendors and we do this 
                            regularly for a range of UK sellers. We also have a 
                            dedicated team which inputs medical content into a 
                            Learning Management System (LMS) for one of the 
                            world’s leading pharma companies. This involves porting 
                            content from Microsoft Powerpoint to the LMS system 
                            via a sophisticated admin interface. The content added is 
                            across 12 odd languages including Chinese, Japanese 
                            and Taiwanese and is used by their employees across 
                            the world.
                            <br/><br/>
                            We have also handled one off requests from clients to 
                            port data from Wordpress or create training sheets using a 
                            software called Automata. Our design team is competent in Adobe tools 
                            and can take on any standard jobs like creation of charts and graphs.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Content Creation Head"
                            image='../brochure-img/Content-Creation-head.png'
                            title="Content Creation Head"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`seo" ${(selectedTab === 'seo' || selectedTab === '') ? '' : 'hide' }`}> 
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="PPC for"
                            image='../brochure-img/Amazon-Seller.png'
                            title="PPC for"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Amazon Seller<br/>		
                                Services
                            </h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/search-ico-two.png' alt="Uploading of content" title="Uploading of content" /></div>
                                <p>Uploading of content for 
                                    various sellers via 
                                    Amazon Seller Services. 
                                    Collating data and 
                                    images in correct format 
                                    so that they can be 
                                    uploaded to Amazon. 
                                    Raising cases where 
                                    content is not displayed 
                                    correctly to get it sorted.
                                    Downloading sales data
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/shoping.png' alt="Downloading sales data" title="Downloading sales data" /></div>
                                <p>
                                    Downloading sales data 
                                    and presenting a weekly 
                                    report format.
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Amazon-ico.png' alt="Amazon" title="Amazon" /></div>
                                <p>Uploading of content to 
                                    other similar sites like 
                                    Ebay.
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2019</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={`seo" ${(selectedTab === 'seo' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Multinational Pharma Company"
                            image='../brochure-img/Multinational.png'
                            title="Multinational Pharma Company"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Multinational <br/>
                                Pharma Company		
                            </h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Inputting.png' alt="Inputting content" title="Inputting content" /></div>
                                <p>Inputting content into a 
                                    Learning Management 
                                    System (LMS) from 
                                    Powerpoint files and 
                                    other sources. Content in 
                                    more than 12 languages 
                                    including Asian 
                                    languages like Chinese, 
                                    Japanese and 
                                    Taiwanese.
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Involves.png' alt="Involves creation" title="Involves creation" /></div>
                                <p>
                                    Involves creation of 
                                    interactive content using 
                                    a WSYWIG creator similar 
                                    to Figma which is part of 
                                    the LMS admin.
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/group.png' alt="Creation of graphs" title="Creation of graphs" /></div>
                                <p>Creation of graphs and 
                                    charts in Adobe 
                                    Photoshop using the 
                                    Powerpoint graphs.
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2009</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide  className={`content-creation" ${(selectedTab === 'content-creation' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>Augmented Reality <br/>Specialist</h2>
                        <p>
                            Augmented Reality (AR) is one of our latest areas of 
                            expertise which sits within the front-end 
                            development team headed by myself, Niraj Borkar.
                            <br/><br/>
                            AR allows you to overlay rich media content onto 
                            normal life situations. This is usually achieved by 
                            smart glasses or by leveraging the mobile phone 
                            camera and overlaying animations or other objects 
                            in the camera feed. 
                            <br/><br/>
                            AR can be used to show more information when you 
                            view a monument or it can be used to trigger 
                            animations when you view a billboard. You can 
                            place objects like furniture into your room to see 
                            how it would look there. And it can also be used for 
                            making education much more interactive by playing 
                            videos when you view certain parts of a book.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="Augmented Reality Specialist"
                            image='../brochure-img/Augmented.png'
                            title="Augmented Reality Specialist"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide  className={`content-creation" ${(selectedTab === 'content-creation' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={4} align={'left'}>
                        <CardMedia
                            component="img"
                            alt="Halloween AR for Capri-Sun"
                            image='../brochure-img/Capri-Sun.png'
                            title="Halloween AR for Capri-Sun"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}  align={'left'}>
                        <div className='smallImgWraper'>
                            <h2>Halloween AR <br/>
                                for Capri-Sun
                            </h2>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/Capri-Sun-ico.png' alt="To add more fun" title="To add more fun" /></div>
                                <p>To add more fun to the 
                                    Halloween edition of 
                                    the Capri-Suns pouches 
                                    it was decided to add 
                                    some animation
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/The-idea.png' alt="The idea" title="The idea" /></div>
                                <p>
                                    The idea was that when 
                                    children view the packet 
                                    through the web app, it 
                                    would play Halloween 
                                    animations and sound. 
                                    Each of the 8 motives 
                                    has a unique animation.
                                </p>
                            </div>
                            <div className='iconsTextBlock'>
                                <div className="icons"><img src='../brochure-img/The-challenge.png' alt="The challenge" title="The challenge" /></div>
                                <p>The challenge for us was 
                                    to get the AR sensing to 
                                    work correctly given the 
                                    deformity in the packet 
                                    and the reflections on 
                                    the pouch surface.
                                </p>
                            </div>
                            <h3 align={'center'}>Client since 2009</h3>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide  className={`content-creation" ${(selectedTab === 'content-creation' || selectedTab === '') ? '' : 'hide' }`}>
                <Grid container>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={7} align={'left'}>
                        <h2>AI/ML Expert</h2>
                        <p>
                            I am Suraj and I am leading our venture into 
                            Artificial Intelligence (AI) and Machine Learning 
                            (ML).
                            <br/><br/>
                                We have been experimenting with Google Tensor 
                                Flow for text based analysis as also image and 
                                video analysis. Besides that, we are working 
                                extensively with Python and OpenCV to analyse 
                                and process images. Given our expertise in all 
                                areas of development right from the web, server 
                                admin and cybersecurity we are able to solve a 
                                myriad set of problems encountered by AI/ML 
                                scripts.
                            <br/><br/>
                            For one of our client SightAI in Singapore, we 
                            managed to get the popular ML library YoloX to 
                            work on multi GPU machines which was a 
                            challenge as it utilised only one GPU even if 
                            multiple existed.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                    <Grid item xs={12} md={2} align={'right'}>
                        <CardMedia
                            component="img"
                            alt="AI/ML Expert"
                            image='../brochure-img/AI-ML-Expert.png'
                            title="AI/ML Expert"
                            className="CoverPic"
                        />
                    </Grid>
                    <Grid item xs={12} md={1} align={'left'}></Grid>
                </Grid>
            </SwiperSlide> */}
        </Swiper>
        <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
        >

            {jsonData.map((slide) => (
                <SwiperSlide className="thumbnails_wrap">  
                    
                    <div className="thumbnails-description" dangerouslySetInnerHTML={{ __html: slide.heading }} /> 
                    <img src={slide.thumbnailimage} alt={slide.heading}/>
                 </SwiperSlide>                          
                              
                    
            ))}

                  
            {/* <SwiperSlide className="thumbnails_wrap">  
                <div className="thumbnails-description">Director & CO-Founder</div>
                <img src='../brochure-img/Nilesh-thumbnails.png' alt="Director & CO-Founder"/>
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Director & CO-Founder</div>
                <img src='../brochure-img/Jonny-thumbnails.png' alt="Director & CO-Founder"/>
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">My Family Care</div>
                <img src='../brochure-img/MFC-thumbnails.png' alt="My Family Care" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Learnify LMS</div>
                <img src='../brochure-img/Learnify-LMS-thumbnails.png' alt="Learnify LMS"/>
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">CTO & C0-Founder</div>
                <img src='../brochure-img/Anurag-thumbnails.png' alt="CTO & C0-Founder" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Lead Developer</div>
                <img src='../brochure-img/pallavi-thumbnails.png' alt="Lead Developer" />
            </SwiperSlide>
        
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">The Lakes</div>
                <img src='../brochure-img/The-Lakes-thumbnails.png' alt="The Lakes" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">PCCW</div>
                <img src='../brochure-img/PCCW-thumbnails.png' alt="PCCW" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Verbatim</div>
                <img src='../brochure-img/Verbatim-thumbnails.png' alt="Verbatim" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">WebPro.legal</div>
                <img src='../brochure-img/WebPro-thumbnails.png' alt="WebPro.legal" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Weltkulturerbelauf</div>
                <img src='../brochure-img/Weltkulturerbelauf-thumbnails.png' alt="Weltkulturerbelauf" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">SUCK UK</div>
                <img src='../brochure-img/SuckuK-thumbnails.png' alt="SUCK UK" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Storath-Chocolatier</div>
                <img src='../brochure-img/Storath-Chocolatier-thumbnails.png' alt="Storath-Chocolatier" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Server Team Head</div>
                <img src='../brochure-img/mohin-thumbnails.png' alt="Server Team Head" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">App Developer</div>
                <img src='../brochure-img/app-thumbnails.png' alt="App Developer" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">My Family Care BUC App</div>
                <img src='../brochure-img/BUC-App-thumbnails.png' alt="My Family Care BUC App" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Nanny app</div>
                <img src='../brochure-img/Nanny-app-thumbnails.png' alt="Nanny app<" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">SGN app</div>
                <img src='../brochure-img/sgn-app-thumbnails.png' alt="SGN app" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Lichtspiel Apple Watch App</div>
                <img src='../brochure-img/Lichtspiel-thumbnails.png' alt="Lichtspiel Apple Watch App" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Senior Developer</div>
                <img src='../brochure-img/Senior-thumbnails.png' alt="Senior Developer" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Ethical Hacking</div>
                <img src='../brochure-img/Ethical-Hacking-thumbnails.png' alt="Ethical Hacking" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Security Audit</div>
                <img src='../brochure-img/Security-Audit-thumbnails.png' alt="Security Audit" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Stress Test</div>
                <img src='../brochure-img/Stress-Test-thumbnails.png' alt="Stress Test" />
            </SwiperSlide>   
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Digital Marketing Manager</div>
                <img src='../brochure-img/Digital-Marketing-thumbnails.png' alt="Digital Marketing Manager" />
            </SwiperSlide>               
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">SEO for UHS Group</div>
                <img src='../brochure-img/SEO-thumbnails.png' alt="SEO for UHS Group" />
            </SwiperSlide> 
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">PPC for Emergencychildcare.co.uk</div>
                <img src='../brochure-img/Emergencychildcare-thumbnails.png' alt="PPC for Emergencychildcare.co.uk" />
            </SwiperSlide> 
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Content Creation Head</div>
                <img src='../brochure-img/Content-creatio-thumbnails.png' alt="Content Creation Head" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Amazon Seller Services</div>
                <img src='../brochure-img/Amazon-thumbnails.png'alt="Amazon Seller Services"  />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Multinational Pharma Company</div>
                <img src='../brochure-img/Pharma-company-thumbnails.png' alt="Multinational Pharma Company" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Augmented Reality Specialist</div>
                <img src='../brochure-img/Augmented-thumbnails.png' alt="Augmented Reality Specialist" />
            </SwiperSlide>
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">Halloween AR for Capri-Sun</div>
                <img src='../brochure-img/Capri-Sun-thumbnails.png' alt="Halloween AR for Capri-Sun" />
            </SwiperSlide>  
            <SwiperSlide className="thumbnails_wrap">
                <div className="thumbnails-description">AI/ML Expert</div>
                <img src='../brochure-img/AIML-Expert-thumbnails.png' alt="AI/ML Expert" />
            </SwiperSlide>                         */}


        </Swiper>
    </div>
    </>
  );
}
export default BrochureSlider
