import React, { Fragment, useState, useEffect } from "react";
import "../css/videogallery.scss";
import { NavLink, useParams, useLocation } from "react-router-dom";
import Videodata from "../data/videodata.json";
import PageNotFound from "./PageNotFound";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function VideoGallery() {
  const params = useParams();

  let query = useQuery();

  const userSessionString = query.get("session") ? '?session='+query.get("session") : '';
  const userSession = query.get("session") ? query.get("session") : '';

  let videoAddress = "ar";
  const [active, setActive] = useState(params.id ? params.id : videoAddress);
  const [showPara, setShowPara] = useState(false);

  let activeData = Videodata.filter((item) => item.slug === active)[0];
  let data = {'userSession': userSession, 'videoId': params.id} ;

  useEffect(() => {
    setActive(params.id ? params.id : videoAddress);
    activeData = Videodata.filter((item) => item.slug === active)[0];
  }, [params]);

  const displayPara = () => {
    setShowPara((showPara) => !showPara);
  };

  useEffect(() => {

    if(userSession)
    {
      fetch(process.env.REACT_APP_API_DOMAIN+'videotrack',{
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
      });
    }

  }, [params.id]);

    
  if (activeData === undefined) {
    return <PageNotFound />;
  }
  return (
    <div className="main-wrapper viedoGalleryWrapper">
      <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grow in={true}>						
          <CardMedia
            component="img"
            alt="Video gallery title"
            image="../page-headline/videoGallery-title.png"
            title="Video gallery title"
            className="page_header_image"
          />
        </Grow>
        
        <Box pb={4}>
          <Typography className={`topText`}>
            ...that at sapnagroup we offer a wide range of different services - watch the videos below to find out more.
          </Typography>
        </Box>
      </Grid>
      </Grid>
      <div className="carousel">
        <div className="video-wrapper">
          <iframe
            src={activeData?.to}
            title={activeData?.name}
            allowFullScreen
            frameBorder="0"
            name="slider"
          ></iframe>
        </div>

        <div className="video-title-wrapper">
          <div className={`main-video-title`}>
            <div>
              <p onClick={displayPara}>{activeData?.name}</p>
            </div>
            <div
              onClick={displayPara}
              className={`accordion-icon ${showPara ? "up-Videodataow" : ""}`}
            ></div>
          </div>
          {showPara && (
            <p className="item-description">
              {activeData?.description}
            </p>
          )}
        </div>

        <div className="thumbnail-wrapper">
          {Videodata.filter((filItem) => filItem.slug !== active).map(
            (item) => (
              <Fragment key={item.id}>
                <NavLink
                  onClick={() => {
                    window.scrollTo({ top: 110, behavior: "smooth" });
                  }}
                  className="thumbnail-holder"
                  to={`/videos/${item.slug}${userSessionString}`}
                >
                  <div className="thumbnail-image">
                    <img src={item.url} alt="video-thumbnail" />
                  </div>
                  <div className="thumbnail-content">
                    <p
                      className="thumbnail-title disable-dev"
                      title={item.name}
                    >
                      {item.name}
                    </p>
                    <p className="dev-title disable-dev">
                    </p>
                  </div>
                </NavLink>
              </Fragment>
            )
          )}

          <NavLink
            className={`thumbnail-holder ${
              activeData?.slug === videoAddress ? "active" : ""
            }`}
            to={`/videos/${activeData?.slug}${userSessionString}`}
          >
            <div className="thumbnail-image">
              <img src={activeData?.url} alt="content" />
            </div>
            <div className="thumbnail-content">
              <p
                className="thumbnail-title active-dev"
                title={activeData?.name}
              >
                {activeData?.name}
              </p>
              <p className="dev-title active-dev">
              </p>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default VideoGallery;
