import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import {scroll_top,seo_tags} from '../js/helper.js'


const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px',
	textAlign:'left'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '18px;',
    fontFamily:'Rajdhani',
    color: '#000'
  },
  container_div: {
    padding:'0 40px',
    border:'0px solid black'
  },
  sub_title:{
	  fontSize:'18px',
	  fontFamily:'rajdhani',
	  padding:'20px 0'
  },
  sub_para:{
	  fontSize:'18px',
	  fontFamily:'rajdhani'
  },
  unordered_list:{
	  paddingLeft:'15px'
  }
}));

function HostingTerms() {
	seo_tags({
      title: 'Hosting Terms & Conditions'
    });

	const classes = useStyles();
    useEffect(() => {
		scroll_top();
	},[]);

	const mailToOnlineStatus = () =>{
		let emailto = String.fromCharCode(111,110,108,105,110,101,115,116,97,116,117,115,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109);
		window.location = 'mailto:'+ emailto +'?';
	   
	}
	return (
		<div className ="maxWrapper">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
                        <h1>Hosting Terms & Conditions</h1>
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
						<ol>
							<li><p><a href="#dedicatedServer">sapnagroup Virtual and Dedicated Server Service Level Agreement</a> </p></li>
							<li><p><a href="#hosting">General Hosting / Server Management Terms & Conditions</a> </p></li>
							<li><p><a href="#aupolicy">Acceptable use policy</a> </p></li>
						</ol>

						<ol className="hosting-css"><span id="dedicatedServer">&nbsp;</span>
			<li><h4>sapnagroup Virtual and Dedicated Server Service Level Agreement</h4>
				<ul className ="listpadding">
					<li><p>At sapnagroup we aim to deliver the highest possible levels of up-time and agree that the Hardware on which your Virtual machine is based or Server Hardware and service critical infrastructure, including Power and Network Connectivity will be available for 99.99% of the time (excluding scheduled maintenance, advance notice of which will be given wherever possible or problems beyond our control, like British Telecom having a limited problem connecting to German Servers.).</p></li>
					<li>
					<p>
					In the event of an outage to one of these service components which results in server downtime You may be entitled to claim a service credit of one day's service fee for every hour that the server is unavailable, depending upon the outage circumstances. The period of the outage will be measured from the time you report the problem to our confirmation of restoration of service.
					</p></li>
					<li>
					<p>
					3 SLA claims may be made up to a maximum of one month's service fee in any calendar month. Please refer to section 16 Liability for full details on when its applicable.
					</p></li>
					<li>
					<p>
					Outages caused by third party software installations or other modifications to the default server operating system as deployed do not fall within the terms of this Service Level Agreement.
					</p></li>
					
				</ul>
			</li>
			<span id="hosting"></span>
				<li><h4>General Hosting / Server Management Terms & Conditions</h4>
					<ul className ="listpadding">
						<li><p>If any of these General Terms & Conditions are inconsistent with any terms set out in sapnagroup Service specific Terms & Conditions, the Service specific Terms & Conditions shall prevail.</p></li>
						<li>
						<p>
						By signing up for the Services You acknowledge and accept that Your use of the Services must be in accordance with the Acceptable Use Policy which can be viewed at the bottom of this document.
						</p></li>
						<li>
						<p>
						By nature of owning a sapnagroup hosting package, You accept these Terms & Conditions.
						</p>
						</li>
						<p><strong><span>1</span>&nbsp;&nbsp;Definitions</strong></p>
						<p><span>1.1</span>&nbsp;&nbsp;”Agreement" means any agreement to which these terms & conditions are incorporated.</p>
						<p><span>1.2</span>&nbsp;&nbsp;”sapnagroup” means sapnagroup Limited with registered office at The Old Wheel House, 31/37 Church Street, Reigate, Surrey, RH2 0AD, UK, company number 4533465.</p>
						<p><span>1.3</span>&nbsp;&nbsp;"Package" means a collection of Services.</p>
						<p><span>1.4</span>&nbsp;&nbsp;”Prices" means the Prices for the Services set out in order form or as otherwise notified to You.</p>
						<p><span>1.5</span>&nbsp;&nbsp;”Services" means the hosting and server management services to be provided by sapnagroup.</p>
						<p><span>1.6</span>&nbsp;&nbsp;”You/Your" means the person or company who purchases Services from sapnagroup.</p>
						
						<p><strong><span>2</span>&nbsp;&nbsp;Duration and Renewal of Services</strong></p>
						<p><span>2.1</span>&nbsp;&nbsp;The following Services have a 1 month minimum contract period:<br/>
							- website hosting<br/>
							- server management<br />
							They auto-renew 2 weeks before the end of the contract period. The invoice period is either monthly, quarterly or annual.	
						</p>
						<p><span>2.2</span>&nbsp;&nbsp;The following Services have a 12 months minimum contract period:<br/>
						- Domains<br />
						- SSL certificates<br />
						They auto-renew 1 months before the end of the contract period. The invoice period is annual.</p>
						
						<p><strong><span>3</span>&nbsp;&nbsp;Cancellations</strong></p>
						<p><span>3.1</span>&nbsp;&nbsp;You are entitled to cancel the services in writing (via email) at any time before the auto-renew of the contract period (see 2). Once sapnagroup accept your cancellation request you will be provided with written confirmation (via email). Cancellation requests will not be deemed to have been received and accepted until we have issued our written confirmation to you.	
						</p>
						<p><span>3.2</span>&nbsp;&nbsp;sapnagroup reserves the right to cancel and/or suspend Your Service at any time without notice if You breach these General Terms & Conditions and/or the Service Specific Terms & Conditions and/or our Acceptable Use Policy.</p>
						
						<p><strong><span>4</span>&nbsp;&nbsp;Refunds</strong></p>
						<p><span>4.1</span>&nbsp;&nbsp;For Charges due on a pre-pay basis You will be entitled to a pro rata refund based upon the remaining period of Your current contract term.	
						</p>
						<p><span>4.2</span>&nbsp;&nbsp;The same will apply in the event that sapnagroup cancels Your Service for reasons other than Your breach of contract.</p>
						<p><span>4.3</span>&nbsp;&nbsp;Domain and SSL charges are non-refundable as they enable the purchase of domain names at discounted Prices, based on an up-front commitment.</p>
						
						<p><strong><span>5</span>&nbsp;&nbsp;Payment</strong></p>
						<p><span>5.1</span>&nbsp;&nbsp;In ordering a sapnagroup Service You agree to commit to the contract for the fixed minimum contract period. It is not possible to provide early payment and terminate the contract prior to the expiry of the fixed contract period.	
						</p>
						<p><span>5.2</span>&nbsp;&nbsp;All Services and Packages will be invoiced in advance.	
						</p>
						<p><span>5.3</span>&nbsp;&nbsp;Invoices will be issued at the of the month before the beginning of a new invoicing period which can be monthly, quarterly or annually based on the Service.</p>
						<p><span>5.4</span>&nbsp;&nbsp;Payment terms are 30 days from receipt of the invoice via email.</p>
						<p><span>5.5</span>&nbsp;&nbsp;sapnagroup reserves the right to change the Prices and/or nature of its Services by giving You written notice of those changes. Notice of changes to Prices and/or Services will be given by email. The change in the Price or nature of that Service will only become effective when the Service reaches the end of its current term. You will be charged the new Price when the Service is automatically renewed at the end of the current term.	
						</p>
						<p><span>5.6</span>&nbsp;&nbsp;All payments must be made in the currency stated on the invoice (either UK pounds sterling or euro), inclusive of applicable taxes.</p>
						<p><span>5.7</span>&nbsp;&nbsp;Payments can be made via cheque or bank transfer.</p>
						<p><span>5.8</span>&nbsp;&nbsp;sapnagroup reserves the right to suspend all Services until payment is received in full and all outstanding debt is cleared. You are responsible for all money owed on the account from the time it was established until sapnagroup accepts Your cancellation request. You are responsible for any additional costs incurred by sapnagroup in the collection of outstanding debt.	
						</p>
						<p><span>5.9</span>&nbsp;&nbsp;No bills or invoices will be sent by regular mail. All invoices will be sent directly to You via email shortly after the order or shortly before the auto-renewal renewal is due.</p>
						<p><span>5.10</span>&nbsp;&nbsp;If You fail to pay all Prices due, sapnagroup reserves the right to interrupt, suspend or cancel the Services to You. Such interruption, suspension or cancellation does not relieve You from paying all contractually obligated invoices to sapnagroup.</p>
						
						<p><strong><span>6</span>&nbsp;&nbsp;Appropriate Service use</strong></p>
						<p><span>6.1</span>&nbsp;&nbsp;sapnagroup reserves the right to refuse Service and/or access to its servers and/or Services to anyone.	
						</p>
						<p><span>6.2</span>&nbsp;&nbsp;sapnagroup does not allow any content which breaches our Acceptable Use Policy to be stored on its servers. sapnagroup reserves the right to remove content from the Services or suspend the Services immediately where it reasonably suspects such content breaches the Acceptable Use Policy.</p>
						<p><span>6.3</span>&nbsp;&nbsp;sapnagroup shall notify You if it becomes aware of any allegation that You breach the Acceptable Use Policy.</p>
						<p><span>6.4</span>&nbsp;&nbsp;Refusal of Service based on the content being contrary to our Acceptable Use Policy is entirely at the discretion of sapnagroup.
						</p>
						<p><span>6.5</span>&nbsp;&nbsp;sapnagroup reserves the right to move Your data to a different server with no prior notice.</p>
						<p><span>6.6</span>&nbsp;&nbsp;You shall indemnify sapnagroup against all damages, losses and expenses arising as a result of any action or claim that the data, content and/or any other material breaches the Acceptable Use Policy.</p>
						<p><span>6.7</span>&nbsp;&nbsp;In the event that sapnagroup removes data or content from the Services and/or suspends Your site pursuant to clause 6.2, and later reinstates such content and/or resumes the Services, You shall indemnify sapnagroup against all damages, losses and expenses arising as a result of any action or claim that such content and/or data and/or the Services Your site breaches the Acceptable Use Policy.	
						</p>
						<p><strong><span>7</span>&nbsp;&nbsp;Scheduled maintenance</strong></p>
						<p><span>7.1</span>&nbsp;&nbsp;To guarantee optimal performance on the servers, it is necessary for sapnagroup to perform routine maintenance. Such maintenance often requires taking sapnagroup Services off-line, typically performed during off-peak hours. sapnagroup will give You advance notice of maintenance requiring the Services to be taken off-line whenever possible by email.	
						</p>
						<p><strong><span>8</span>&nbsp;&nbsp;Support</strong></p>
						<p><span>8.1</span>&nbsp;&nbsp;We will endeavour to provide a continuous high quality service. If You experience problems with Your Service, You can contact us through these channels:<br/>
						- Email: <span  onClick={() => mailToOnlineStatus()} style={{textDecoration:'underline', cursor:'pointer'}}>onlinestatus@sapnagroup.com</span> <br />
						- Phone UK: <a href="tel:01737 / 887808">01737 / 887808</a>, options 1, 2, 4 or 6 <br />
						- Phone Germany: <a href="tel:0951 / 97459999">0951 / 97459999</a> <br />
						- Whatsapp to the following numbers: <a href="tel:+44 7889 361798">+44 7889 361798</a>, <a href="tel:+91 98-22-156967">+91 98-22-156967</a>, <a href="tel:+91 98-22-584066">+91 98-22-584066</a> <br />
						You will also find the sapnagroup 24/7 emergency contact us form at the bottom of sapnagroup website at <a href="https://www.sapnagroup.com">http://www.sapnagroup.com</a>	
						</p>
						<p><span>8.2</span>&nbsp;&nbsp;Please note we may require suspension of some of sapnagroup Services for short scheduled periods to carry out maintenance or repair to sapnagroup Services. Information concerning scheduled downtime is available on <a href="https://www.sapnagroupstatus.com" target="_blank" rel="noopener noreferrer">http://www.sapnagroupstatus.com</a>, as are details of any interruptions to sapnagroup Services.	
						</p>
						
						<p><strong><span>9</span>&nbsp;&nbsp;Reselling of Services</strong></p>
						<p><span>9.1</span>&nbsp;&nbsp;All Services are intended for use by the primary owner only, should you choose to resell, store or give away web-hosting Services to other parties You agree that such activity will be undertaken at your own risk. You also accept responsibility for ensuring that all end users abide by these terms of service.	
						</p>
						<p><span>9.2</span>&nbsp;&nbsp;sapnagroup accept no liability to you or any third parties for losses arising from the Reselling of services as detailed in 9.1.	
						</p>
						<p><span>9.3</span>&nbsp;&nbsp;sapnagroup reserve the right to suspend access to the automated management facilities of the Reseller account including the API (Application Programming Interface) if a customer's use is deemed to be affecting the platform for which sapnagroup delivers the Services.
						</p>
						
						<p><strong><span>10</span>&nbsp;&nbsp;Database Usage</strong></p>
						<p><span>10.1</span>&nbsp;&nbsp;If You exceed the limits on your hosting package (traffic, data storage, MySQL, email) we will notify you and you will then have 30 days to reduce the usage, upgrade your hosting package with us or move your website to another ISP. After the 30 days we reserve the right to close down your hosting package.	
						</p>
						
						<p><strong><span>11</span>&nbsp;&nbsp;Data</strong></p>
						<p><span>11.1</span>&nbsp;&nbsp;All data created or stored by You within sapnagroup' applications and servers are Your property. sapnagroup shall allow access to such data by only authorised sapnagroup personnel. sapnagroup makes no claim of ownership of any web server content, email content, or any other type of data contained within the accountholder's server space or within applications on sapnagroup' servers.	
						</p>
						<p><span>11.2</span>&nbsp;&nbsp;sapnagroup maintains backups of its servers and infrastructure pursuant to its own archiving and business continuity procedures. We also store daily backups of up to 7 days for you. In the event of loss of or damage to your data we can restore these backups for you.	
						</p>
						<p><span>11.3</span>&nbsp;&nbsp;In the event of loss of or damage to your data relating to a failure in sapnagroup systems or servers, sapnagroup will make reasonable commercial efforts to assist you in the restoration of your data.	
						</p>
						<p><span>11.4</span>&nbsp;&nbsp;You shall indemnify sapnagroup against all damages, losses and expenses arising as a result of any action or claim that the content or data of Your site or content or data accessed from or published as part of the Services infringes the intellectual property rights of a third party.	
						</p>
						
						<p><strong><span>12</span>&nbsp;&nbsp;Uploads via scripting languages</strong></p>
						<p><span>12.1</span>&nbsp;&nbsp;We limit uploads made via scripting language (PHP). Uploads made using PHP are limited to 20MB per file.	
						</p>
						<p><span>12.2</span>&nbsp;&nbsp;We reserve the right to disallow execution of files in directories for uploads via scripting languages.	
						</p>
						
						<p><strong><span>13</span>&nbsp;&nbsp;Passwords</strong></p>
						<p><span>13.1</span>&nbsp;&nbsp;It is the account owner's responsibility to keep his/her password(s) confidential, and to change the password on a regular basis. sapnagroup is not responsible for any data losses or security issues due to stolen passwords or any passwords that You have intentionally or accidentally disclosed to any third party. sapnagroup recommends that You use passwords that contain combination of alphabets, numbers and symbols in order to prevent unauthorized users from guessing commonly-used choices (i.e. "12345", "password", etc.).	
						</p>
						
						<p><strong><span>14</span>&nbsp;&nbsp;Personal details and communications</strong></p>
						<p><span>14.1</span>&nbsp;&nbsp;Client understands that the contact details of the Clients and all parties (contractors, suppliers etc) involved and the communications may be shared outside the EEA as well with our staff members and suppliers etc, only for the purpose of executing our service. The sharing of these details will enable understanding the requirements and help communicate with all the relevant parties. This will also include sensitive access details like passwords to databases, FTP accounts, google analytics etc as and where relevant.

						</p>
						
						<p><strong><span>15</span>&nbsp;&nbsp;sapnagroup Disclaimers and Warranties</strong></p>
						<p><span>15.1</span>&nbsp;&nbsp;sapnagroup does back up your data/website and whilst every attempt would be made in the unlikely event of any corruption or hardware failure, sapnagroup cannot guarantee to be able to fully replace lost data. This includes loss of data resulting from delays, non-deliveries, wrong delivery, and any and all Service interruptions caused by sapnagroup.	
						</p>
						<p><span>15.2</span>&nbsp;&nbsp;sapnagroup makes no warranties or representations that any Service will be uninterrupted or error-free. You accept all Services provided hereunder "as is" without warranty of any kind.
						</p>
						<p><span>15.3</span>&nbsp;&nbsp;So far as permitted by law and particularly in respect of non-consumers, all implied conditions, warranties and terms (whether express or implied by statute, common law, custom or otherwise) including, but not limited to, those relating to the exercise of reasonable care and skill, fitness for purpose and satisfactory quality (where applicable) are hereby excluded in relation to each of the Services to be provided hereunder to the fullest extent permitted by law.
						</p>
						<p><span>15.4</span>&nbsp;&nbsp;For the avoidance of doubt, any use of the Services and/or any Package in the course of conducting business shall give rise to you being a non-consumer and the provisions of this Agreement affecting the statutory consumer protection you would otherwise be afforded as a consumer shall not apply.
						</p>
						
						<p><strong><span>16</span>&nbsp;&nbsp;Liability</strong></p>
						<p><span>16.1</span>&nbsp;&nbsp;sapnagroup shall not be liable for any loss or damage of whatsoever nature suffered by You arising out of or in connection with any breach of this Agreement by You or any act, misrepresentation, error or omission made by You or on Your behalf.
						</p>
						<p><span>16.2</span>&nbsp;&nbsp;sapnagroup will not be liable for any indirect loss, consequential loss, loss of profit, revenue, data or goodwill howsoever arising suffered by You or for any wasted management time or failure to make anticipated savings or liability You incur to any third party arising in any way in connection with this Agreement or otherwise whether or not such loss has been discussed by the parties pre-contract or for any account for profit, costs or expenses arising from such damage or loss.
						</p>
						<p><span>16.3</span>&nbsp;&nbsp;No matter how many claims are made and whatever the basis of such claims, sapnagroup' maximum aggregate liability to You under or in connection with this Agreement in respect of any direct loss (or any other loss to the extent that such loss is not excluded by other provisions in this Agreement) whether such claim arises in contract or in tort shall not exceed a sum equal to the fees paid by You for the Services in relation to which Your claim arises during the 12 month period prior to such claim.
						</p>
						<p><span>16.4</span>&nbsp;&nbsp;None of the clauses herein shall apply so as to restrict liability for death or personal injury resulting from the negligence of sapnagroup, its employees or its sub-contractors.
						</p>
						<p><span>16.5</span>&nbsp;&nbsp;sapnagroup shall not be liable for any interruptions to the Services or outages arising directly or indirectly from:-
						</p>
						<p><span>16.5.1</span>&nbsp;&nbsp;interruptions to the flow of data to or from the internet;
						</p>
						<p><span>16.5.2</span>&nbsp;&nbsp;changes, updates or repairs to the network or software which it uses as a platform to provide the Services;
						</p>
						<p><span>16.5.3</span>&nbsp;&nbsp;the effects of the failure or interruption of Services provided by third parties;
						</p>
						<p><span>16.5.4</span>&nbsp;&nbsp;factors outside of sapnagroup' reasonable control;
						</p>
						<p><span>16.5.5</span>&nbsp;&nbsp;Your actions or omissions (including, without limitation, breach of Your obligations set out in the Agreement) or those of any third parties;
						</p>
						<p><span>16.5.6</span>&nbsp;&nbsp;problems with Your equipment and/or third party equipment;
						</p>
						<p><span>16.5.7</span>&nbsp;&nbsp;interruptions to the Services requested by You.
						</p>
						
						<p><strong><span>17</span>&nbsp;&nbsp;Force Majeure</strong></p>
						<p><span>17.1</span>&nbsp;&nbsp;sapnagroup shall not be responsible for any failure to provide any Services or perform any obligation under the Agreement because of any act of God, strike, lock-outs or other industrial disputes (whether involving the workforce of sapnagroup (or any other party) or compliance with any law of governmental or any other order, rule, regulation or direction, accident, fire, flood, storm or default of suppliers, work stoppage, war, riot or civil commotion, equipment or facilities shortages which are being experienced by providers of telecommunication Services generally, or other similar force beyond its reasonable control.
						</p>
						
						<p><strong><span>18</span>&nbsp;&nbsp;Non-Waiver</strong></p>
						<p><span>18.1</span>&nbsp;&nbsp;The failure of sapnagroup to require Your performance of any provision shall not affect the full right to require such performance at any time thereafter; nor shall the waiver by sapnagroup of a breach of any provision hereof be taken or held to be a waiver of the provision itself.
						</p>
						
						<p><strong><span>19</span>&nbsp;&nbsp;Survival</strong></p>
						<p><span>19.1</span>&nbsp;&nbsp;The provisions, terms, conditions representations, warranties, covenants, and obligations contained in or imposed by this Agreement which by their performance after the termination of this Agreement, shall be and remain enforceable notwithstanding termination of the Agreement for any reason. However, neither party shall be liable to other for damages of any sort resulting solely from terminating this Agreement in accordance with its terms but each party shall be liable for any damage from any breach by it of this Agreement.
						</p>
						
						<p><strong><span>20</span>&nbsp;&nbsp;Notice</strong></p>
						<p><span>20.1</span>&nbsp;&nbsp;You agree that any notice or communications required or permitted to be delivered under this Agreement by sapnagroup to You shall be deemed to have been given if delivered by e-mail, in accordance with the contact information You have provided.
						</p>
						
						<p><strong><span>21</span>&nbsp;&nbsp;Governing Law</strong></p>
						<p><span>21.1</span>&nbsp;&nbsp;Except as otherwise set forth in the Domain Dispute Policy, Your rights and obligations and all contemplated by this Agreement shall be governed by English law and You submit to the exclusive jurisdiction of the English Courts.
						</p>
						
						<p><strong><span>22</span>&nbsp;&nbsp;Legal Fees</strong></p>
						<p><span>22.1</span>&nbsp;&nbsp;If any legal action or proceeding, including arbitration, relating to the performance or the enforcement of any provision of this Agreement is brought by any party to this Agreement, the prevailing party shall be entitled to recover reasonable legal fees, expert witness fees, costs and disbursements, in addition to any other relief to which the prevailing party may be entitled.
						</p>
						
						<p><strong><span>23</span>&nbsp;&nbsp;Assignment</strong></p>
						<p><span>23.1</span>&nbsp;&nbsp;You shall not assign, sub-license or transfer Your rights or obligations under this Agreement to any third party without the prior written consent of sapnagroup However, in the event that sapnagroup consents to such an assignment, sub-license or transfer, then this Agreement shall ensure to the benefit of and be binding upon the parties and their respective successors and permitted assigns.
						</p>
						
						<p><strong><span>24</span>&nbsp;&nbsp;Entire Agreement</strong></p>
						<p><span>24.1</span>&nbsp;&nbsp;This Agreement constitutes the entire Agreement between the parties and agreements are representations or warranties, express or implied, statutory or otherwise and no agreements collateral here to than as expressly set or referred to herein. This Agreement supersedes any prior agreements, representations, statements, negotiations, understandings, proposals or undertakings, oral or written, with respect to the subject matter expressly set forth herein.
						</p>
						
						<p><strong><span>25</span>&nbsp;&nbsp;Amendment in Writing</strong></p>
						<p><span>25.1</span>&nbsp;&nbsp;We may update or amend these General Terms and Conditions, the Service Specific Terms & Conditions including any technical specification relating to the Services and/or Package, the Acceptable Use Policy, Privacy Policy and any information relating to the Services/Package from time to time to comply with law or to meet our changing business requirements. We will give You prior notice of any changes to the Agreement and You can choose to cancel the Services without penalty before the new terms affect you. Display of the modified terms and conditions shall be deemed to be notice to you. You also agree to review the terms and conditions regularly to ensure you are aware of any modifications.
						</p>
						
						<p><strong><span>26</span>&nbsp;&nbsp;Further Assurances</strong></p>
						<p><span>26.1</span>&nbsp;&nbsp;The parties shall execute such further and other documents and instruments and take such further and other actions as may be necessary to carry out and give full effect to the transactions contemplated by this Agreement.
						</p>
						
						<p><strong><span>27</span>&nbsp;&nbsp;Relationship of the Parties</strong></p>
						<p><span>27.1</span>&nbsp;&nbsp;Nothing in this Agreement shall be construed as creating an agency relationship, partnership or joint venture between the parties.
						</p>
						
						<p><strong><span>28</span>&nbsp;&nbsp;Joint and Several Obligations</strong></p>
						<p><span>28.1</span>&nbsp;&nbsp;If any party consists of more than one entity, their obligations here under are joint and several.
						</p>
						
						<p><strong><span>29</span>&nbsp;&nbsp;No Third Party Beneficiaries</strong></p>
						<p><span>29.1</span>&nbsp;&nbsp;This Agreement does not provide and shall not be constructed to provide any third parties, with any remedy, claim, cause of action or privilege.
						</p>
						
						
						<p><strong><span>30</span>&nbsp;&nbsp;Severability</strong></p>
						<p><span>30.1</span>&nbsp;&nbsp;In the event that any provision of this Agreement shall be unenforceable or invalid under any applicable law or be so held by applicable court decision, such unenforceability or invalidity shall not render this Agreement unenforceable or invalid as a whole. sapnagroup will amend or replace such provision with one that is valid and enforceable and which achieves, to the extent possible, the original objectives and intent of sapnagroup as reflected in the original provision.
						</p>
						
						<p><strong><span>31</span>&nbsp;&nbsp;Server OS, PHP and MySQL Upgrades</strong></p>
						<p><span>31.1</span>&nbsp;&nbsp;You understand and accept that from time to time the OS (operating system) of the web server and with it PHP (programming language) and MySQL (database) will be required to be upgraded for security reasons. This usually happens once in 2-3 years. In many cases (especially if your website / application is older) this also requires an upgrade to the code of your website / application. The costs for these upgrades after a major OS updates are not covered in our annual hosting fees. sapnagroup programming division will inform you of the costs for such a code upgrade at least 3 months before we upgrade the server. 
						</p>
					</ul>
				</li>
				<span id="aupolicy"></span>
				<li><h4>Acceptable use policy</h4>
					<ul className ="listpadding">
						<li><p>This policy is subject to change, so please check regularly for updates. This policy is in addition to sapnagroup Terms & Conditions.</p></li>
						
						<p><strong><span>1</span>&nbsp;&nbsp;Use of Services</strong></p>
						<p><span>1.1</span>&nbsp;&nbsp;sapnagroup reserves the right to suspend or cancel a customer's access to any or all Services provided by sapnagroup, where sapnagroup decides that the account has been inappropriately used. sapnagroup reserves the right to refuse Services and/or access to its servers to anyone.</p>
						<p><span>1.2</span>&nbsp;&nbsp;sapnagroup provides unlimited, burstable bandwidth with some of the services and for these resources we require that you do not continuously use an unreasonable excess of such resources. Use of them in a temporary, burstable fashion is acceptable.sapnagroup offer large numbers of mailboxes with some account types subject to a fair use policy.</p>
						<p><span>1.3</span>&nbsp;&nbsp;Mailboxes are intended for direct use by the package owner and should not be resold or offered for use by third parties. Should the number of active mailboxes assigned to an individual package exceed 49, sapnagroup reserve the right to restrict the number of mailboxes included in the package price.</p>
						<p><span>1.4</span>&nbsp;&nbsp; sapnagroup reserve the right to delete mailboxes (including contents) that are deemed inactive, i.e. have not been accessed within the last 180 days.</p>
						<p><span>1.5</span>&nbsp;&nbsp;sapnagroup will suspend access to, or cancel, an account which:</p>
						<p><span>1.5.1</span>&nbsp;&nbsp;Contains invalid or fraudulent details.</p>
						<p><span>1.5.2</span>&nbsp;&nbsp;Initiates a payment card chargeback.</p>
						<p><span>1.6</span>&nbsp;&nbsp;You agree;</p>
						<p><span>1.6.1</span>&nbsp;&nbsp;not to re-sell or offer for the use of third parties any part of our services, unless we have agreed a reseller package on a dedicated server.</p>
						
						<p><strong><span>2</span>&nbsp;&nbsp;Acceptable use guidelines</strong></p>
						<p><span>2.1</span>&nbsp;&nbsp;You may not use the Services:</p>
						<p><span>2.1.1</span>&nbsp;&nbsp;in any way that breaches any applicable regulation , UK or International law.</p>
						<p><span>2.1.2</span>&nbsp;&nbsp;in any way that is fraudulent;</p>
						<p><span>2.1.3</span>&nbsp;&nbsp;to host any material which is in breach of our content guidelines (see section 3);</p>
						<p><span>2.1.4</span>&nbsp;&nbsp;to transmit, or otherwise permit any unsolicited or unauthorised advertising or promotional material or any other form of similar marketing material ,also known as SPAM or Unsolicited Commercial E-mail (UCE)</p>
						<p><span>2.1.5</span>&nbsp;&nbsp;to publish or otherwise distribute Warez, copywritten or other illegal material. The onus is on You the customer to prove that You own the rights to publish material, not for sapnagroup to prove that You do not.</p>
						<p><span>2.1.6</span>&nbsp;&nbsp;to participate in any file-sharing/peer-to-peer activities;</p>
						<p><span>2.1.7</span>&nbsp;&nbsp;as a file backup store. All files uploaded to a domain on our servers must be visible and accessible by visiting that domain, unless they are hidden files needed to operate the website; we reserve the right to delete files which do not match these criteria without giving notice to you.</p>
						<p><span>2.1.8</span>&nbsp;&nbsp;to use the server as an application server. Using the server to generate large volumes of email from a database is an example of activity that is not allowed. Scripts should not attempt to manipulate the timeouts on servers. These are set at the present values to ensure the reliability of the server. Sites that reset these do so because they are resource intensive, and adversely affect server performance and are therefore not allowed. Scripts that are designed to provide proxy Services, anonymous or otherwise, are not allowed</p>
						<p><span>2.1.9</span>&nbsp;&nbsp;to use an excessive amount of server resources. These include bandwidth, processor utilization and / or disk space.</p>
						<p><span>2.1.10</span>&nbsp;&nbsp;to offer HTTP downloads from your site, other than where the files are directly related to the subject matter of the site. We recommend you stream any multimedia content including audio and video files. Any downloads offered by You under this clause are subject to sections 1.2, 2.1.6, and </p>
						<p><span>2.1.11</span>&nbsp;&nbsp;of this Acceptable Use Policy. sapnagroup reserves the right to archive any download content we do not believe to be necessary for the normal operation of the site, and the definition of this is entirely at the discretion of sapnagroup.</p>
						<p><span>2.1.12</span>&nbsp;&nbsp;to redirect users from a non-.XXX website to a .XXX website</p>
						
						<p><strong><span>3</span>&nbsp;&nbsp;Content guidelines</strong></p>
						<p><span>3.1</span>&nbsp;&nbsp;These content guidelines apply to any and all services provided by sapnagroup to You.</p>
						<p><span>3.2</span>&nbsp;&nbsp;Material published to or transmitted through our servers must:</p>
						<p><span>3.2.1</span>&nbsp;&nbsp;be accurate (where stating facts);</p>
						<p><span>3.2.2</span>&nbsp;&nbsp;comply with applicable law in accordance with section 2.1.1 of this policy.</p>
						<p><span>3.3</span>&nbsp;&nbsp;Material must not:</p>
						<p><span>3.3.1</span>&nbsp;&nbsp;be defamatory of any person;</p>
						<p><span>3.3.2</span>&nbsp;&nbsp;be obscene, offensive, or inflammatory;</p>
						<p><span>3.3.3</span>&nbsp;&nbsp;contain any adult material with the exception of content hosted on .XXX domains. Adult material includes all pornography; or otherwise lewd or obscene content. The designation of 'adult material' is left entirely to the discretion of sapnagroup</p>
						<p><span>3.3.4</span>&nbsp;&nbsp;material hosted on .XXX domains must not breach any of the IFFOR policies. These include, but are not limited to:</p>
						<p><span>3.3.4.1</span>&nbsp;&nbsp;.XXX registrants must not display any photograph, film, video, picture, or computer or computer-generated image or picture, whether made or produced by electronic, mechanical, or other means, depicting child pornography as defined in the United Nations Convention on the Rights of the child.</p>
						<p><span>3.3.4.2</span>&nbsp;&nbsp;.XXX registrants must agree to permit automated monitoring of their sites by IFFOR or if applicable, any other recognised third party for compliance with IFFOR policies, including without limitation, IFFOR policies requiring site labelling, prohibiting child pornography, and prohibiting content or conduct designed to suggest the presence of child pornography. Registrants must agree not to employ technological or other means to defeat or prevent such monitoring.</p>
						<p><span>3.3.4.3</span>&nbsp;&nbsp;No registrant shall use or permit use of a .XXX domain name for or in connection with email spoofing, phishing, spam, or other forms of malicious behaviour, in accordance with specifications and policies issued by IFFOR from time to time.</p>
						<p><span>3.3.4.4</span>&nbsp;&nbsp;No registrant may register an abusive string in the sTLD including, without limitation, strings that infringe the intellectual property rights of a third party, including common law trademark rights; strings that are obvious variants of well-known trademarks not belonging to the registrant; first and last names of an individual other than the individual or his/her agent or names that suggest the presence of child pornography.</p>
						<p><span>3.3.5</span>&nbsp;&nbsp;infringe any copyright, or trade mark.;</p>
						<p><span>3.3.6</span>&nbsp;&nbsp;be likely to deceive any person;</p>
						<p><span>3.3.7</span>&nbsp;&nbsp;promote or assist any illegal activity;</p>
						<p><span>3.3.8</span>&nbsp;&nbsp;be threatening, abusive or invade another's privacy, or cause annoyance, inconvenience or needless anxiety;</p>
						<p><span>3.3.9</span>&nbsp;&nbsp;be used to to misrepresent your identity or affiliation with any person or organization;</p>
						<p><span>3.4</span>&nbsp;&nbsp;You must ensure that any end-user submitted content such as forum posts or chat room entries meet with the standards defined within this Policy.</p>
						
						
						<p><strong><span>4</span>&nbsp;&nbsp;Enforcement</strong></p>
						<p><span>4.1</span>&nbsp;&nbsp;We will determine, in our discretion, whether there has been a breach of this acceptable use policy through your use of sapnagroup Services. When a breach of this policy has occurred, we may take such action as we deem appropriate in accordance with section 1.1 of this Acceptable Use Policy.</p>
						<p><span>4.2</span>&nbsp;&nbsp;Failure to comply with this Acceptable Use Policy constitutes a material breach of our Terms and Conditions upon which you are permitted to use sapnagroup Services, and may result in our taking all or any of the following actions:</p>
						<p><span>4.2.1</span>&nbsp;&nbsp;immediate, temporary or permanent withdrawal of your right to use sapnagroup Services;</p>
						<p><span>4.2.2</span>&nbsp;&nbsp;immediate, temporary or permanent removal or archiving of any content in breach of our Content Standards (as defined in section 3 of this Acceptable Use Policy) uploaded to our servers;</p>
						<p><span>4.2.3</span>&nbsp;&nbsp;issue of a warning to you;</p>
						<p><span>4.2.4</span>&nbsp;&nbsp;issue of legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach;</p>
						<p><span>4.2.5</span>&nbsp;&nbsp;further legal action against you; or
						disclosure of such information to law enforcement authorities as we reasonably feel is necessary.</p>
						<p><span>4.3</span>&nbsp;&nbsp;We exclude liability for actions taken in response to breaches of this acceptable use policy. The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate.</p>
						
					</ul>
				</li>	
			</ol>
					</Grid>
				</Grow>

			</Grid>
		</div>
	)
}

export default HostingTerms
