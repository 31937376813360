import React, {  useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

//import Switch from '@material-ui/core/Switch';

//import ToggleDarkMode from './ToggleDarkMode';

import { Link } from "react-router-dom";

import '../css/index.scss';

import {remove_activeLink} from '../js/helper.js'

const useStyles = makeStyles((theme) => ({
  menu_icon:{
      float:'left',
      fontSize:'40px'
  },
  menuButton: {
    marginRight: theme.spacing(3),
    border:'1px solid white'
  },
  title: {
    flexGrow: 1
  }
}));

function Header() {
    const classes = useStyles();

    var navi_links = document.getElementsByClassName("navi_link"); 
    useEffect(() => {
        for (var i = 0; i < navi_links.length; i++) {
            navi_links[i].addEventListener('click', setActive, false);
        }
        
        setActiveUrl();
    });

    function setActiveUrl(){
        var urlPath = window.location.pathname;
        urlPath = urlPath.replace("/", "");
        if(urlPath!=='' && urlPath!=='ftpsetup' && urlPath!=='impressum' && urlPath!=='security-policy' && urlPath!=='policy-and-cookies'){
            /* --make header links active-- */
            var naviObj = document.getElementById("navi_"+urlPath);
            if(document.body.contains(naviObj)){
                naviObj.classList.add('active');
            }
        }else if(urlPath===''){
            /* --if pathname is blank make home link active-- */
            document.getElementById("navi_home").classList.add('active');
        }
    }

    function setActive(obj){
        remove_activeLink();
        obj.target.classList.add('active')
    }

    // const changeDarkMode = (event) => {
    //     console.log(darkMode);
    //     { darkMode?toggleDarkModeOff():toggleDarkModeOn()}
    // };

    // function toggleDarkModeOn(){
    //     updateDarkMode(1);
    //     document.body.classList.add('dark-mode');
    // }

    // function toggleDarkModeOff(){
    //     updateDarkMode(0);
    //     document.body.classList.remove('dark-mode');
    // }
    return (
        <React.Fragment>
            <header>
                {/* -----mobile header----- */}
                <div id="header-containerMobile">                    
                    <div className="logo-container">
                        <Link to="/">
                            <img className="site-logo" src="/logo.png" alt=""/>
                        </Link>
                        <div className="banner-contaier">
                            <img className="banner-logo" src="/top-baner.png" alt=""/>
                        </div>
                    </div>                   
                </div>
                {/* <ToggleDarkMode/> */}
                        {/* <Switch
                            checked={darkMode}
                            onChange={changeDarkMode}
                            color="#000000"
                            name="dark-mode"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                /> */}

                {/* -----desktop header----- */}
                <div id="header-containerDesk">
                    <AppBar id="menu_navigation" position="fixed" color="inherit" >
                        <Toolbar>
                            <div id="desk_logoContainer">
                                <Link to="/">
                                    <img className="site-logo" src="./logo.png" alt=""/>
                                </Link>
                            </div>

                            <Typography className={classes.title}>

                            </Typography>
                            
                            <Link to="/" id="navi_home" className="navi_link" >
                               Home
                            </Link>
                            
                            {/* <Link to="/services" id="navi_services" className="navi_link">
                               Services
                            </Link> */}
                            <Link to="/news" id="navi_news" className="navi_link" >
                               News
                            </Link>
                            <Link to="/about" id="navi_about" className="navi_link" >
                               About
                            </Link>
                            <Link to="/teams" id="navi_teams" className="navi_link" >
                               Team
                            </Link>
                           {/* <Link to="/academy" id="navi_academy" className="navi_link" >
                               Academy
                            </Link> */}
                            <Link to="/jobs-page" id="navi_jobs-page" className="navi_link" >
                               Jobs
                            </Link>
                            <Link to="/tools" id="navi_tools" className="navi_link" >
                               Tools
                            </Link>
                            <Link to="/Brochure" id="navi_tools" className="navi_link" >
                             Brochure
                            </Link>
                            <Link to="/contact" id="navi_contact" className="navi_link">
                               Contact
                            </Link>
                        </Toolbar>
                    </AppBar>
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header
