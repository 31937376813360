import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import {scroll_top,seo_tags} from '../js/helper.js'

const useStyles = makeStyles((theme) => ({
  padded_para:{
    paddingBottom:'30px',
	textAlign:'left'
  },
  page_title: {
    textAlign: 'center',
    fontSize: '18px;',
    fontFamily:'Rajdhani',
    color: '#000'
  },
  container_div: {
    padding:'0 40px',
    border:'0px solid black'
  },
  sub_title:{
	  fontSize:'18px',
	  fontFamily:'rajdhani',
	  padding:'20px 0'
  },
  sub_para:{
	  fontSize:'18px',
	  fontFamily:'rajdhani'
  },
  unordered_list:{
	  paddingLeft:'15px'
  }
}));

function Gdpr() {

	seo_tags({
      title: 'GDPR, Privacy and Cookies policy'
    });
	
	const classes = useStyles();

	useEffect(() => {
		scroll_top();
	},[]);

	const mailToHello = () =>{
		let emailto = String.fromCharCode(104,101,108,108,111,64,115,97,112,110,97,103,114,111,117,112,46,99,111,109);
		window.location = 'mailto:'+ emailto +'?';
	   
	}

	return (
		<div className ="maxWrapper">
			<Grid className={classes.container_div} container px={5}>
				<Grow in={true}>
					<Grid className={classes.page_title} item xs={12}>
						<CardMedia
							component="img"
							alt="GDPR, Privacy and Cookies policy"
							image="../page-headline/gdpr-privacy.png"
							title="GDPR, Privacy and Cookies policy"
							className="page_header_image_height"
						/>
					</Grid>
				</Grow>

				<Grow in={true}>
					<Grid className={classes.padded_para} item xs={12}>
						<Typography className={classes.sub_title}>
							What personal information we have, why, and where do we store it?
						</Typography>
						<Typography className={classes.sub_para}>
							sapnagroup maintains an online CRM (Customer Relationship Management) system used for communicating with clients for project management, business and marketing purpose.
							As data controllers we store basic contact information which includes first name, last name, company, office address, email, telephone, mobile number, fax, MSN contact, Skype contact and VAT number about our clients, agencies, business partners, sub-processors and employees and communications with them.
							Additionally, your contact details will also reside on personal contact list like but not limiting to Google contact list or address book, and/or on social and networking tools like Whatsapp/Skype/Facebook/Instagram etc. We may also share this information with third parties when we need to use their service or when we feel they might be beneficial to you. This information is not restricted to EEA.
						</Typography>


						<Typography className={classes.sub_title}>
							Communication with us
						</Typography>
						<Typography className={classes.sub_para}>
							When you send email or other communications to sapnagroup or any of our team members, we may retain and store those communications in order to process your inquiries, respond to your requests and improve our services.
						</Typography>

						<Typography className={classes.sub_title}>
							Email alerts
						</Typography>
						<Typography className={classes.sub_para}>
							Email alerts might be sent as a part of business development or for marketing purpose. No bulk mailers are used for sending any communication. We will not rent or sell your email address to anyone else. If you are unhappy about the emails we are sending you please email <span onClick={() => mailToHello()} style={{textDecoration:'underline', cursor:'pointer'}} >hello@sapnagroup.com</span> and inform us of the email address you wish to remove.
						</Typography>

						<Typography className={classes.sub_title}>
							SMS alerts
						</Typography>
						<Typography className={classes.sub_para}>
							sapnagroup uses SMS alerts to communicate and inform clients and business partners of any emergency situation including but not limiting server failures or maintenance mode of their system. If you are unhappy about the SMS we are sending you please email <span onClick={() => mailToHello()} style={{textDecoration:'underline', cursor:'pointer'}} >hello@sapnagroup.com</span> and inform us of the mobile phone number you wish to remove.
						</Typography>

						<Typography className={classes.sub_title}>
							What lawful basis do we have to process this information?
						</Typography>
						<Typography className={classes.sub_para}>
							The lawful basis for this information is contract with the individual or company and/or legitimate interests: As we are a private-sector organisation and can process personal data without consent if we have a genuine and legitimate reason (including commercial benefit)
						</Typography>


						<Typography className={classes.sub_title}>
							How long we retain this information for?
						</Typography>
						<Typography className={classes.sub_para}>
							We may store this information for continuing our business relationship, and even keep this information beyond any active business relationship for any future perspective business.
						</Typography>


						<Typography className={classes.sub_title}>
							Data processors
						</Typography>
						<Typography className={classes.sub_para}>
							sapnagroup is also a data processor for multiple data controllers. This is mainly due to the nature of our service where we have access to the data but are not part of the operations team etc, eg we do server hosting and maintenance and web development, but we don't carry out operational activities like contacting the end user etc.
						</Typography>


						<Typography className={classes.sub_title}>
							Sub-processors
						</Typography>
						<div className={classes.sub_para} style={{fontWeight: '400',lineHeight:'1.5',letterSpacing: '0.00938em',}}>
							Our main sub processors are
							<ul className={classes.unordered_list}>
								<li>
								sapnagroup deutschland, Claus Hübner e.K. who handle German project management and administrative work for us
								</li>
								<li>
								Sapna Technologies an IT company in India which we use for our services which include software development and server hosting/maintenance.
								</li>
								<li>
								Hetzner (www.hetzner.de) which we use for hosting requirements. Hetzner does not have software access to our servers as it does not have any OS accounts.
								</li>
								<li>
								Strato (<a href="https://www.strato-hosting.co.uk/" target="_blank" rel="noopener noreferrer">https://www.strato-hosting.co.uk/</a>) used for remote backup solutions
								</li>
								<li>
								AWS (<a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">https://aws.amazon.com/</a>) used for hosting services like files etc.
								</li>
								<li>
								G Suite, Gmail, Google Drive, Google Calendar, Dropbox, iCloud, Slack and similar services
								</li>
							</ul>

							In rare cases we may use a different provider as data processors and such cases are noted with the data controller.
						</div>


						<Typography className={classes.sub_title}>
							What are your rights for your personal information?
						</Typography>
						<div className={classes.sub_para} style={{fontWeight: '400',lineHeight:'1.5',letterSpacing: '0.00938em',}}>
							You can contact us at hello@sapnagroup.com for any of the following requests for the personal information we hold of you<br></br>
							<ul className={classes.unordered_list}>
								<li> to get details of the information we hold of you i.e. subject access requests</li>
								<li> to correct/update this information</li>
								<li> to delete this information</li>
								<li> to restrict processing of this information</li>
								<li> to object</li>
								<li> to not be subjected to automated decision making including profiling.</li>
								<li> to provide this information in portable format</li>
							</ul>
						</div>


						<Typography className={classes.sub_title}>
							Whom to contact if you have queries or complain?
						</Typography>
						<Typography className={classes.sub_para}>
							If you have any queries or complains you can write to us at <span onClick={() => mailToHello()} style={{textDecoration:'underline', cursor:'pointer'}} >hello@sapnagroup.com</span>
						</Typography>


						<Typography className={classes.sub_title}>
							Cookies
						</Typography>
						<Typography className={classes.sub_para}>
							This website does not use cookies.
							<br></br>
						</Typography>


						<Typography className={classes.sub_title}>
							Logging
						</Typography>
						<Typography className={classes.sub_para}>
							When you visit our website or use our services, our servers log basic information like the IP address, time, request URL, browser details, system details like resolution etc. This information is not used to uniquely identify any visitor and is tracked for statistical purpose and for fault tracking. This information is used for internal purpose only except for generalised statistic information which might be used for marketing purpose. This information is not sold or passed on to third parties or third party systems.
						</Typography>

						<Typography className={classes.sub_title}>
							Information security
						</Typography>
						<Typography className={classes.sub_para}>
							We have taken appropriate steps to ensure your information is safe with us. Please refer to our <a href="{{ $DOCROOT }}home/security_policy">security policy</a>.
						</Typography>


						<Typography className={classes.sub_title}>
							Google analytics
						</Typography>
						<Typography className={classes.sub_para}>
							This website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyse how users use the website. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States . Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. You can read Google Analytics terms of service at <a href="https://www.google.com/analytics/tos.html" target="_blank" rel="noopener noreferrer" >https://www.google.com/analytics/tos.html</a>, and Googles privacy policy can be read at <a href="https://www.google.com/privacy.html" target="_blank" rel="noopener noreferrer" >https://www.google.com/privacy.html</a><br></br><br></br>
						</Typography>

						<Typography className={classes.sub_title}>
							Changes to this Privacy Policy
						</Typography>
						<Typography className={classes.sub_para}>
							sapnagroup may change the privacy policy from time to time. All changes to privacy policy will be on this page.<br></br><br></br>
							If you have any additional questions or concerns about this Privacy Policy, please feel free to contact us any time at <span onClick={() => mailToHello()} style={{textDecoration:'underline', cursor:'pointer'}} >hello@sapnagroup.com</span>
						</Typography>
					</Grid>
				</Grow>
			</Grid>
		</div>
	)
}

export default Gdpr
