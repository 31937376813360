import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';

import Masonry from 'react-masonry-css'

import Linkify from 'react-linkify';


const useStyles = makeStyles((theme) => ({
	grid_item_square: {
		display: 'inline-block',
		border:'0px solid red',
		backgroundSize: '70% 100%',
		backgroundRepeat: 'no-repeat',
		padding: '0',
		backgroundPosition:'center'
	},
	news_type_item: {
		border:'0px solid black'
	},
	news_detail_row: {
		border:'0px solid orange'
	},
	news_image: {
		border: '0px solid black;',
		width:  '100%',
		marginTop: '8px',
		height:'auto'
	},
	news_desc: {
		textAlign: 'center',
		color: '#000',
        fontFamily:'Rajdhani',
        fontSize: '18px',
	},
	news_sub_title: {
		textAlign: 'left',
		color: '#606060',
		fontSize: '21px',
		marginBottom: '20px',
		border: '0px solid black;'
	},
	news_title: {
		textAlign: 'center',
		fontSize: '18px;'
	},
	intro_para: {
		textAlign: 'center',
		paddingBottom: '20px;'
	},
	container_div: {
		padding:'0 20px',
		border:'0px solid black'
	},
	grid_paper:{
		padding:'12px 12px'
	},
	saperator_image:{
		width:'50%',
		padding:'25px 0',
		margin:'0 auto'
	},
	read_more:{
	},
	news_bold:{
		fontWeight:'bold',
		fontFamily:'Rajdhani'
	}
}));

function NewsGrid(props) {
	const [newsCount, setNewsCount] = useState(8);
	const [readMore, setReadMore] = useState(1);
	
	const classes = useStyles();
	let newsData = props.newsData;
	let saperatorData = props.saperatorData;

	const breakpointColumnsObj = {
		/*default: 3,
		1100: 3,
		700: 2,
		500: 1*/
		
		default: 3,
		767: 1,
		576: 1
		
	};

  
	useEffect(() => {
		if((newsCount)>=newsData.length){
			setReadMore(0)
		}
	},[newsCount, newsData.length] );

	const loadMoreNews = () =>{
		setNewsCount((prevValue) => prevValue + 8)
		if((newsCount+8)>=newsData.length){
			setReadMore(0)
		}
	}

	const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );
	

	return (
		<React.Fragment>
			<Grid className="container_div maxWrapper newsList" container>
				<Grow in={true}>
					<Grid className={classes.news_title} item xs={12}>
						<CardMedia
						  component="img"
						  alt="News title"
						  image="../page-headline/news.png"
						  title="News title"
						  className="page_header_image"
						/>
					</Grid>
				</Grow>
				<Grow in={true}>
					<Grid className={`${classes.intro_para} newsSubPara`} item xs={12}>
						We also publish our news on our social networking channels.
						Follow us on <a href="https://www.facebook.com/sapnagroup" target="_blank" rel="noopener noreferrer" className={classes.news_bold} >Facebook</a>, <a href="https://www.instagram.com/sapnagroup/" target="_blank" rel="noopener noreferrer" className={classes.news_bold}>Instagram</a>, <a href="https://www.linkedin.com/in/jonnyhubner" target="_blank" rel="noopener noreferrer" className={classes.news_bold}>Linkedin</a>, <a href="https://www.xing.com/profile/Jonny_Huebner2" target="_blank" rel="noopener noreferrer" className={classes.news_bold}>Xing</a> and <a href="https://twitter.com/sapnagroup" target="_blank" rel="noopener noreferrer" className={classes.news_bold}>Twitter</a>. 
					</Grid>
				</Grow>
			</Grid>	
			<div className="maxWrapper newsList">
				<Grid className="container_div" container spacing={6} align={'left'}>	
					<Hidden mdUp>
						<div style={{ marginTop: '20px', height: '1px', }}>&nbsp;</div>
					</Hidden>
					<Hidden smDown>
						<div style={{ marginTop: '50px', height: '1px', }}>&nbsp;</div>
					</Hidden>
					<Masonry
						breakpointCols={breakpointColumnsObj}
						className="my-masonry-grid"
						columnClassName="my-masonry-grid_column"
					>
						{newsData.slice(0,newsCount).map((item,index) => (
						<div key={index} className="grid_content">
							<Grow in={true} >
								<Grid className={`${classes.grid_item_square} newsGridBox`} item xs={12} sm={12} md={12} lg={12} >
									<CardMedia
										component="img"
										alt="Sapeartor image"
										image={"../divider-images/divider"+(saperatorData[index])+".png"}
										title={index}
										className={`${classes.saperator_image} seperatorImg`}
									/>
									<Typography className={classes.news_desc} >
										<Linkify componentDecorator={componentDecorator}>
											<span className={classes.news_bold} >
												[{item.date}]
											</span>
											&nbsp;{item.story}
										</Linkify>
									</Typography>

									<CardMedia
										component="img"
										alt={item.name}
										height="50"
										image={item.filename}
										title={item.name}
										className={classes.news_image}
									/>
								</Grid>
							</Grow>
						</div>
					))}

					</Masonry>
					
				</Grid>
			</div>

			{
				readMore ?
				<div id="read_more_div">
					<div  className={classes.read_more} onClick={() => {loadMoreNews();} } style={{cursor:'pointer', textDecoration:'underline'}}>More</div>
				</div>
				: null
			}
			
        </React.Fragment>
	)
}

export default NewsGrid
