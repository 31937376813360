import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Markdown from 'react-markdown';

import {saperator_index} from '../js/helper.js'

const useStyles = makeStyles((theme) => ({
  nilesh_image: {
    width:'200px',
	height:'200px',
	margin:'0 auto'
  },
  about_image:{
    width:'100%',
    height:'auto',
    maxWidth:'777px',
    margin:'0 auto'
  },
  testimonial_image: {
    width:'150px',
	height:'150px',
	margin:'0 auto'
  },
  sign_image: {
    width:'80%',
	height:'auto'
  },
  jobs_desc_grid:{
	  padding:'20px 10px',
	  fontFamily:'inherit',
	  // maxWidth: '250px',
	  margin: '0px auto 30px auto'
  },
  jobs_desc_grid_intro:{
	  padding:'20px 0 0',
	  fontFamily:'inherit',
	  //maxWidth: '250px',
	  margin: '0 auto 0 auto'
  },
  jobs_para:{
	  fontFamily:'inherit',
	  margin: '15px 0'
  },
  nilesh_signature:{
	  textAlign:'left',
	  
  },
	footer_text:{
		fontFamily:'inherit'
	},
	testimonial_qualif:{
		fontFamily:'inherit'
	},
  intro_para:{
    //paddingBottom:'30px'
  },
  container_div: {
    padding:'0 30px',
    border:'0px solid black'
  },
  slider_caption:{
    fontFamily:'Rajdhani',
    fontSize:'14px',
    padding:'5px 5px 10px 5px',
    lineHeight:'16px'
  },
  saperator_image:{
    width:'147px',
    padding:'15px 0 30px 0',
    margin:'0 auto',
    maxWidth:''
  }
}));

function AboutGrid(props) {
  const classes = useStyles();
  let aboutData = props.aboutData;

  return (
    <React.Fragment>
		<Grid className="maxWrapper aboutUsPage" container px={5}>
			<Grow in={true}>
				<Grid className={classes.services_title} item xs={12}>
					<CardMedia
						component="img"
						alt="About title"
						image="../page-headline/about.png"
						title="About title"
						className="page_header_image"
					/>
				</Grid>
			</Grow>

			<Grow in={true}>
				<Grid className={`${classes.intro_para} textDiv`} item xs={12}>
				We don't like blurb but the few paragraphs below give you an idea of who we are. Get in touch with us! Let's meet so that we can tell you our full story, and more importantly, listen to your story and your requirements.
				</Grid>
			</Grow>
			
			{aboutData.map((item,index) => (
				<Grid item xs={12} className={classes.jobs_desc_grid_intro} key={index}>

					<CardMedia
						component="img"
						alt="Sapeartor image"
						image={"../divider-images/divider"+(saperator_index())+".png"}
						title={index}
						className={classes.saperator_image}
					/>
					
					<p className="textDiv">
						<Markdown linkTarget="_blank">{item.story.replace(/[\r\n]+/g, '\r\n')}</Markdown>
					</p>

					<CardMedia
						component="img"
						alt=""
						image={item.filename}
						title=""
						className={classes.about_image}
					/>

					<Typography className={classes.slider_caption}>
						{item.caption}
					</Typography>
					
					<br />
				</Grid>
			))}
		  
		</Grid>
	</React.Fragment>
  )
}

export default AboutGrid
